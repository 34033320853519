import React, { FC } from "react";

const ThreeUserIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="29"
      height="22"
      viewBox="0 0 29 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4327 13.8935C9.88502 13.8935 6.04785 14.6366 6.04785 17.5047C6.04785 20.3739 9.9098 21.0909 14.4327 21.0909C18.9804 21.0909 22.8176 20.3478 22.8176 17.4797C22.8176 14.6105 18.9557 13.8935 14.4327 13.8935Z"
        fill="#623CE7"
      />
      <path
        opacity="0.4"
        d="M14.4324 11.1611C17.5118 11.1611 19.981 8.67768 19.981 5.58054C19.981 2.48222 17.5118 0 14.4324 0C11.353 0 8.88379 2.48222 8.88379 5.58054C8.88379 8.67768 11.353 11.1611 14.4324 11.1611Z"
        fill="#623CE7"
      />
      <path
        opacity="0.4"
        d="M26.4798 6.87969C27.2765 3.74576 24.9407 0.931152 21.9664 0.931152C21.6429 0.931152 21.3337 0.966765 21.0316 1.02731C20.9914 1.0368 20.9466 1.05698 20.923 1.0926C20.8958 1.13771 20.9159 1.19825 20.9454 1.23742C21.8389 2.49812 22.3523 4.03304 22.3523 5.68073C22.3523 7.25957 21.8814 8.73157 21.0552 9.95309C20.9702 10.0789 21.0457 10.2487 21.1956 10.2748C21.4033 10.3116 21.6158 10.3306 21.833 10.3365C23.9988 10.3935 25.9428 8.99154 26.4798 6.87969Z"
        fill="#623CE7"
      />
      <path
        d="M28.7486 14.2589C28.352 13.4089 27.3948 12.826 25.9395 12.54C25.2525 12.3714 23.3935 12.134 21.6644 12.166C21.6384 12.1696 21.6243 12.1874 21.6219 12.1993C21.6184 12.2159 21.6255 12.2444 21.6597 12.2622C22.4587 12.6599 25.5476 14.3895 25.1593 18.0374C25.1428 18.1953 25.2691 18.3318 25.426 18.3081C26.1861 18.1988 28.1419 17.7762 28.7486 16.4597C29.0838 15.7641 29.0838 14.9557 28.7486 14.2589Z"
        fill="#623CE7"
      />
      <path
        opacity="0.4"
        d="M7.96833 1.0278C7.66736 0.966066 7.35693 0.931641 7.03353 0.931641C4.05917 0.931641 1.72335 3.74625 2.52123 6.88018C3.05709 8.99203 5.00105 10.394 7.16691 10.337C7.38408 10.3311 7.59772 10.3109 7.80427 10.2753C7.95417 10.2492 8.02971 10.0794 7.94473 9.95358C7.11851 8.73087 6.64757 7.26005 6.64757 5.68121C6.64757 4.03234 7.16218 2.49742 8.05568 1.23791C8.084 1.19874 8.10525 1.1382 8.07692 1.09309C8.05331 1.05629 8.00964 1.03729 7.96833 1.0278Z"
        fill="#623CE7"
      />
      <path
        d="M3.06023 12.5394C1.60492 12.8255 0.648872 13.4084 0.25229 14.2584C-0.0840966 14.9552 -0.0840966 15.7636 0.25229 16.4604C0.858966 17.7757 2.81473 18.1995 3.57484 18.3075C3.73182 18.3313 3.85694 18.196 3.84041 18.0369C3.45209 14.3901 6.54095 12.6605 7.34119 12.2628C7.37424 12.2438 7.38132 12.2165 7.37778 12.1987C7.37542 12.1869 7.36244 12.1691 7.33647 12.1667C5.60615 12.1334 3.74835 12.3709 3.06023 12.5394Z"
        fill="#623CE7"
      />
    </svg>
  );
};

export default ThreeUserIcon;
