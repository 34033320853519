import React, { FC, useState } from "react";
import { DeleteIcon, FilledPenIcon, PlusIcon } from "assets";
import { CusButton, CusImage } from "common/modules";
import { urlTranformer } from "common/utils";
import { ButtonWithIcon, CusTable } from "components";
import { TableCell, TableRow } from "flowbite-react";
import { CategoryType, SubCategoryType } from "common/types";
import { deleteCategory, deleteRestaurantFeature, deleteSubCategory } from "api";


type props = {
  setOpenedModal: any
  categories?: CategoryType[]
  subCategories?: SubCategoryType[]
  features?: string[]
  handleDelete?: (type: string, value: any) => void
}

const RestaurantSettings: FC<props> = ({
  setOpenedModal,
  categories,
  subCategories,
  features,
  handleDelete
}) => {

  const [catLoading, setCatLoading] = useState<boolean[]>(Array(categories?.length).fill(false));
  const [subCatLoading, setSubCatLoading] = useState<boolean[]>(Array(subCategories?.length).fill(false))
  const [featuresLoading, setFeaturesLoading] = useState<boolean[]>(Array(features?.length).fill(false))

  const onCatDelete = (item: { id: string, name: string }, index: number) => {
    setCatLoading((prev) => prev.map((v, i) => i === index ? true : v)); // set loading for the specific item
    deleteCategory(item.name).then((res) => {
      if (res.success) {
        handleDelete?.("cat", item)
        setCatLoading(Array(categories?.length).fill(false)) // reset loading after deletion
      }
    })
  }

  const onSubCatDelete = (item: { id: string, name: string }, index: number) => {
    console.log(item)
    setSubCatLoading((prev) => prev.map((v, i) => i === index ? true : v)); // set loading for the specific item
    deleteSubCategory({ category: item.id, subCategory: item.name }).then((res) => {
      if (res.success) {
        handleDelete?.("subCat", item)
        setSubCatLoading(Array(categories?.length).fill(false)) // reset loading after deletion
      }
    })
  }

  const onFeatureDelete = (feature: string, index: number) => {
    setFeaturesLoading((prev) => prev.map((v, i) => i === index ? true : v)); // set loading for the specific item
    deleteRestaurantFeature({ feature }).then((res) => {
      if (res.success) {
        handleDelete?.("feature", { index })
        setFeaturesLoading(Array(features?.length).fill(false)) // reset loading after deletion
      }
    })
  }

  return (
    <React.Fragment>
      <div className="grid grid-cols-12 gap-x-4">
        <div className="col-span-4">
          <div className="mb-2">
            <ButtonWithIcon
              onClick={() => setOpenedModal({ title: 'نوع جدید', id: 1 })}
              className="btn-with-icon-yellow"
              icon={<PlusIcon fill="white" />}
              text="نوع جدید"
            />
          </div>
          <CusTable
            wrapperClassName="max-h-[580px]"
            stickyHeader
            noItem={categories?.length === 0}
            headers={["نوع رستوران", "تصویر", "عملیات"]}
            contents={
              categories?.map((cat, i) => {
                return (
                  <TableRow>
                    <TableCell>{cat.persianName}</TableCell>
                    <TableCell><CusImage className="w-14 aspect-square" src={urlTranformer(cat.catImage)} /></TableCell>
                    <TableCell>
                      <div className="flex gap-x-2">
                        <button onClick={() => setOpenedModal({ title: 'ویرایش نوع', id: 1, item: cat })}>
                          <FilledPenIcon className="fill-slate-400 w-4 h-4" />
                        </button>
                        <CusButton
                          loading={catLoading[i]}
                          onClick={() => onCatDelete({ id: cat._id, name: cat.name }, i)}
                          loadingWidth={10}
                          loadingColor="black"
                          className="p-2 bg-rose-300/50 justify-center items-center w-8 h-8 rounded-full bg-opacity-70 drop-shadow-md">
                          <DeleteIcon className="scale-150" />
                        </CusButton>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
          />
        </div>
        <div className="col-span-5">
          <div className="flex justify-between mb-2">
            <ButtonWithIcon
              onClick={() => setOpenedModal({ title: 'دسته جدید', id: 2 })}
              className="btn-with-icon-yellow"
              icon={<PlusIcon fill="white" />}
              text="دسته جدید"
            />
            <button>بیشتر</button>
          </div>
          <CusTable
            wrapperClassName="max-h-[580px]"
            stickyHeader
            noItem={subCategories?.length === 0}
            headers={["دسته", "زیردسته", "تصویر", "عملیات"]}
            contents={
              subCategories?.map((subCat, i) => (
                <TableRow>
                  <TableCell className="w-[175px]">{subCat.persianName}</TableCell>
                  <TableCell className="w-[150px]">{subCat.name}</TableCell>
                  <TableCell><CusImage className="w-14 aspect-square" src={urlTranformer(typeof subCat.imageLogo === "string" ? subCat.imageLogo : "")} /></TableCell>
                  <TableCell>
                    <div className="flex gap-x-2">
                      <button onClick={() => setOpenedModal({ title: 'ویرایش زیردسته', id: 2, item: subCat })}>
                        <FilledPenIcon className="fill-slate-400 w-4 h-4" />
                      </button>
                      <CusButton
                        loadingWidth={10}
                        loadingColor="black"
                        loading={subCatLoading[i]}
                        onClick={() => onSubCatDelete({ id: subCat.category, name: subCat.name }, i)}
                        className="p-2 bg-rose-300/50 justify-center items-center w-8 h-8 rounded-full bg-opacity-70 drop-shadow-md">
                        <DeleteIcon className="scale-150" />
                      </CusButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          />
        </div>
        <div className="col-span-3">
          <div className="mb-2">
            <ButtonWithIcon
              onClick={() => setOpenedModal({ title: 'امکانات جدید', id: 3 })}
              className="btn-with-icon-yellow"
              icon={<PlusIcon fill="white" />}
              text="امکانات جدید"
            />
          </div>
          <CusTable
            wrapperClassName="max-h-[580px]"
            stickyHeader
            noItem={features?.length === 0}
            headers={["امکانات رستوران", "عملیات"]}
            contents={
              features?.map((feature, i) => (
                <TableRow>
                  <TableCell>{feature}</TableCell>
                  <TableCell>
                    <CusButton
                      loadingWidth={10}
                      loadingColor="black"
                      loading={featuresLoading[i]}
                      onClick={() => onFeatureDelete(feature, i)}
                      className="p-2 bg-rose-300/50 justify-center items-center w-8 h-8 rounded-full bg-opacity-70 drop-shadow-md">
                      <DeleteIcon className="scale-150" />
                    </CusButton>
                  </TableCell>
                </TableRow>
              )
              )}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RestaurantSettings;
