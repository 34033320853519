import React from 'react';
import Select, { ActionMeta, MultiValue, StylesConfig } from 'react-select';
import makeAnimated from "react-select/animated"

interface Option {
    value: string;
    label: string;
}

interface CustomMultiSelectProps {
    options: Option[];
    defaultValue?: Option[];
    placeholder: string;
    errorMsg?: string
    onChange: (options: MultiValue<Option>, actionMeta: ActionMeta<Option>) => void;
}

const customStyles: StylesConfig<Option, true> = {
    control: (provided) => ({
        ...provided,
        border: 0,
        boxShadow: 'none',
        backgroundColor: "#F9FAFB"
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '0',
        marginTop: '0'
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'lightblue' : 'white',
        '&:hover': {
            backgroundColor: 'lightgray'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'gray'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#FFCD00',
        borderRadius: "6px",
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#383838',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#383838',
        transition: "all",
        transitionDuration: "300ms",
        '&:hover': {
            backgroundColor: '#006a7a',
            color: 'white'
        }
    })
};

const CusMultiSelect: React.FC<CustomMultiSelectProps> = ({ options, placeholder, onChange, defaultValue, errorMsg }) => {

    const animatedComponents = makeAnimated()

    return (
        <div>
            <Select
                isMulti
                options={options}
                onChange={onChange}
                styles={customStyles}
                placeholder={placeholder}
                defaultValue={defaultValue}
                components={animatedComponents}
            />
            {errorMsg && <p className='text-rose-600 text-xs'>{errorMsg}</p>}
        </div>
    );
};

export default CusMultiSelect
