import React from 'react'

const LoyalCustIcon = () => {
    return (
        <svg className='w-12 h-12' viewBox="20 15 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1399_361)">
                <rect x="24" y="20" width="31.935" height="31.935" rx="7.73684" fill="#3CABDB" />
            </g>
            <g filter="url(#filter1_d_1399_361)">
                <path d="M39.9445 38.6372C36.5463 38.6372 33.679 39.1925 33.679 41.3356C33.679 43.4796 36.5648 44.0154 39.9445 44.0154C43.3428 44.0154 46.2101 43.4601 46.2101 41.317C46.2101 39.173 43.3243 38.6372 39.9445 38.6372Z" fill="white" />
                <path opacity="0.4" d="M39.9445 36.5955C42.2456 36.5955 44.0907 34.7398 44.0907 32.4255C44.0907 30.1103 42.2456 28.2554 39.9445 28.2554C37.6434 28.2554 35.7983 30.1103 35.7983 32.4255C35.7983 34.7398 37.6434 36.5955 39.9445 36.5955Z" fill="white" />
                <path opacity="0.4" d="M48.9466 33.3962C49.5419 31.0544 47.7965 28.9512 45.5739 28.9512C45.3322 28.9512 45.1012 28.9778 44.8754 29.023C44.8454 29.0301 44.8119 29.0452 44.7942 29.0718C44.774 29.1055 44.7889 29.1508 44.811 29.18C45.4787 30.1221 45.8623 31.2691 45.8623 32.5003C45.8623 33.6801 45.5104 34.78 44.893 35.6928C44.8295 35.7868 44.886 35.9137 44.998 35.9332C45.1532 35.9607 45.312 35.9749 45.4742 35.9793C47.0927 36.0219 48.5453 34.9743 48.9466 33.3962Z" fill="white" />
                <path d="M50.6419 38.9103C50.3456 38.2752 49.6303 37.8396 48.5428 37.6259C48.0295 37.4999 46.6404 37.3225 45.3483 37.3464C45.3289 37.3491 45.3183 37.3624 45.3165 37.3713C45.3139 37.3837 45.3192 37.405 45.3448 37.4183C45.9419 37.7154 48.25 39.0079 47.9598 41.7338C47.9475 41.8518 48.0419 41.9538 48.1592 41.9361C48.7271 41.8545 50.1886 41.5387 50.6419 40.5549C50.8924 40.0351 50.8924 39.431 50.6419 38.9103Z" fill="white" />
                <path opacity="0.4" d="M35.1139 29.0235C34.889 28.9774 34.657 28.9517 34.4154 28.9517C32.1928 28.9517 30.4473 31.0549 31.0436 33.3967C31.444 34.9748 32.8966 36.0224 34.515 35.9798C34.6773 35.9754 34.837 35.9603 34.9913 35.9337C35.1033 35.9142 35.1598 35.7873 35.0963 35.6933C34.4789 34.7796 34.127 33.6806 34.127 32.5008C34.127 31.2687 34.5115 30.1217 35.1792 29.1805C35.2003 29.1512 35.2162 29.106 35.195 29.0723C35.1774 29.0448 35.1448 29.0306 35.1139 29.0235Z" fill="white" />
                <path d="M31.4464 37.6256C30.3589 37.8394 29.6445 38.2749 29.3482 38.9101C29.0968 39.4308 29.0968 40.0349 29.3482 40.5556C29.8015 41.5384 31.263 41.8551 31.831 41.9358C31.9483 41.9536 32.0418 41.8524 32.0294 41.7336C31.7392 39.0085 34.0474 37.7161 34.6454 37.4189C34.6701 37.4047 34.6754 37.3843 34.6727 37.371C34.6709 37.3622 34.6612 37.3489 34.6418 37.3471C33.3489 37.3222 31.9606 37.4996 31.4464 37.6256Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_1399_361" x="0.789478" y="0.657899" width="78.3561" height="78.3561" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.86842" />
                    <feGaussianBlur stdDeviation="11.6053" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0.662745 0 0 0 0 0.862745 0 0 0 0.27 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1399_361" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1399_361" result="shape" />
                </filter>
                <filter id="filter1_d_1399_361" x="21.1597" y="24.2554" width="37.6702" height="31.76" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.384314 0 0 0 0 0.235294 0 0 0 0 0.905882 0 0 0 0.31 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1399_361" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1399_361" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default LoyalCustIcon