import AxiosInstance from "IAxios/AxiosInstance";
import { PagintaionType } from "common/types";
import { OrderType } from "common/types/orders";
import { restaurantOrdersSearchType } from "components/RestaurantOrders/RestaurantOrders";
import { toast } from "react-toastify";

interface GetAllOrdersAPI extends PagintaionType {
    data?: OrderType[]
}

export const getAllOrders = (searchTerms?: restaurantOrdersSearchType, params?: { page: number }) =>
    AxiosInstance.post<GetAllOrdersAPI>("orders", searchTerms, { params: { ...params, limit: 10 } })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data?.error));