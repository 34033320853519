import AxiosInstance from "IAxios/AxiosInstance";
import { BackendTemplate } from "api";
import { CategoryType, PagintaionType, RestaurantType, SearchedRestaurants, SubCategoryType } from "common/types";
import { objectToFormData } from "common/utils";
import { toast } from "react-toastify";

export interface RestaurantsAPIType extends PagintaionType {
  data?: RestaurantType[];
}

export const getAllRestaurants = (params?: { name?: string, mphone?: string, page?: number }) =>
  AxiosInstance.get<RestaurantsAPIType>("restaurants", { params: { ...params, limit: 10 } })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export interface LoadSettingsFormAPIType extends BackendTemplate {
  data?: {
    advDistance?: number[]
    advTypes?: string[]
    categoryArr?: CategoryType[]
    featureArr?: string[]
    subCatArr?: SubCategoryType[]
  }
}

export const loadSettingsForm = () =>
  AxiosInstance.get<LoadSettingsFormAPIType>("forms/settings")
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data?.error))

export type NewCategory = {
  name?: string
  persianName?: string
  catImage?: File | string
}

export const updateRestaurant = async (restId: string, data: RestaurantType) => {
  const formData = objectToFormData(data)
  return AxiosInstance.put(`restaurants/${restId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((res) => res.data)
    .catch((err) => {
      if (typeof err?.response?.data.error === "object") {
        toast.error(err?.response?.data.error.join());
        return
      }
      toast.error(err?.response?.data.error)
    })
}

export const deleteRestaurantImage = async (restId: string, imageUrl: string) => {
  const imageId = imageUrl.split("/")[2]
  return AxiosInstance.delete(`restaurants/${restId}/image`, { data: { imageId } })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))
}

export const deleteRestaurantLogo = async (restId: string, logoUrl: string) => {
  const logoId = logoUrl.split("/")[2]
  return AxiosInstance.delete(`restaurants/${restId}/logo`, { data: { logoId } })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))
}

export const getCategories = () =>
  AxiosInstance.get("forms/categories")
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))

interface GetRestaurantByNameAPI extends PagintaionType {
  data?: SearchedRestaurants[] | null
}

export const getRestaurantByName = (data: { name: string }) =>
  AxiosInstance.post<GetRestaurantByNameAPI>("restaurants/name", data)
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))