import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import "./CustomizeCSS.css"


interface TimeRangePickerProps {
    minSlider?: number
    maxSlider?: number
    min?: string;
    max?: string;
    minTime?: string; // Optional prop for minimum selectable time of the day
    maxTime?: string; // Optional prop for maximum selectable time of the day
    onChange?: (range: string[]) => void;
    className?: string
}

// const roundToNearest30Minutes = (timeString: string): string => {
//     let hours = parseInt(timeString.substring(0, 2), 10);
//     let minutes = parseInt(timeString.substring(2, 4), 10);

//     if (minutes < 15) {
//         minutes = 0;
//     } else if (minutes < 45) {
//         minutes = 30;
//     } else {
//         minutes = 0;
//         hours++;
//     }

//     if (hours === 24) {
//         hours = 0;
//     }

//     const formattedHours = hours.toString().padStart(2, '0');
//     const formattedMinutes = minutes.toString().padStart(2, '0');
//     return `${formattedHours}${formattedMinutes}`;
// };

const roundToNearest30Minutes = (timeString?: string): string => {
    if (timeString) {
        let hours = parseInt(timeString.substring(0, 2), 10);
        let minutes = parseInt(timeString.substring(2, 4), 10);

        console.log(hours, minutes)
        if (minutes > 45) {
            minutes = 0;
            hours++;
        } else if (minutes > 15) {
            minutes = 30;
        } else {
            minutes = 0;
        }

        if (hours === 24) {
            hours = 23;
            minutes = 59;
        }

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        return `${formattedHours}${formattedMinutes}`;
    }
    return ""

};


const convertTimeStringToMinutes = (timeString: string): number => {
    const hours = parseInt(timeString.substring(0, 2), 10);
    const minutes = parseInt(timeString.substring(2, 4), 10);

    return hours * 60 + minutes;
};

const convertMinutesToTimeString = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}${mins.toString().padStart(2, '0')}`;
};

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
    min,
    max,
    onChange,
    className,
    minSlider = 0,
    maxSlider = 1440,
    maxTime = "2359",
    minTime = "0000",
}) => {
    const [range, setRange] = useState<number[]>([
        convertTimeStringToMinutes(roundToNearest30Minutes(min)),
        convertTimeStringToMinutes(roundToNearest30Minutes(max)),
    ]);

    useEffect(() => {
        const minMinutes = convertTimeStringToMinutes(roundToNearest30Minutes(min));
        const maxMinutes = convertTimeStringToMinutes(roundToNearest30Minutes(max));
        setRange([minMinutes, maxMinutes]);
    }, [min, max]);

    const handleRangeChange = (value: any) => {

        // const minMinutes = convertTimeStringToMinutes(roundToNearest30Minutes(`${minSlider}`));
        // const maxMinutes = convertTimeStringToMinutes(roundToNearest30Minutes(`${maxSlider}`));
        const minMinutes = minSlider
        const maxMinutes = maxSlider
        const minTimeMinutes = convertTimeStringToMinutes(minTime);
        const maxTimeMinutes = convertTimeStringToMinutes(maxTime);
        const adjustedValue = value.map((val: number) => Math.min(Math.max(val, minMinutes), maxMinutes));

        if (adjustedValue[0] < minTimeMinutes) {
            adjustedValue[0] = minTimeMinutes;
        }

        if (adjustedValue[1] > maxTimeMinutes) {
            adjustedValue[1] = maxTimeMinutes;
        }

        setRange(adjustedValue);
        if (onChange) {
            onChange(adjustedValue.map(convertMinutesToTimeString));
        }
    };



    return (
        <div className={"select-none " + className}>
            <Slider
                range
                pushable
                step={30}
                value={range}
                min={minSlider}
                max={maxSlider}
                onChange={handleRangeChange}
                styles={{
                    handle: { backgroundColor: "#ffcd00" },
                    track: { backgroundColor: "#ffcd00" },
                }}
                marks={{
                    // [convertTimeStringToMinutes(minTime)]: convertMinutesToTimeString(convertTimeStringToMinutes(minTime)).replace(/(\d{2})(\d{2})/, '$1:$2'),
                    // [convertTimeStringToMinutes(maxTime)]: convertMinutesToTimeString(convertTimeStringToMinutes(maxTime)).replace(/(\d{2})(\d{2})/, '$1:$2'),
                    [range[0]]: convertMinutesToTimeString(range[0]).replace(/(\d{2})(\d{2})/, '$1:$2'),
                    [range[1]]: convertMinutesToTimeString(range[1]).replace(/(\d{2})(\d{2})/, '$1:$2'),
                }}
            />
        </div>
    );
};

export default TimeRangePicker;
