import { MenuOffLogo } from 'assets/logos'
import { urlTranformer } from 'common/utils'
import { MiniLoading } from 'components/common'
import React, { FC } from 'react'
import { Img } from "react-image"

type props = {
    src?: string | File
    className?: string
    alt?: string
    style?: React.CSSProperties
}

const CusImage: FC<props> = ({ src, className, alt, style }) => {
    return (
        <Img
            alt={alt}
            style={style}
            className={className}
            src={typeof src === "string" ? urlTranformer(src) : src ? URL.createObjectURL(src) : ""}
            loader={<MiniLoading />}
            unloader={
                <div className='relative'>
                    <MenuOffLogo className='w-12 h-12 opacity-30' />
                    <p className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-20'>بدون تصویر</p>
                </div>
            }
        />
    )
}

export default CusImage