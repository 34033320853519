import React from "react";
import { FullScreenLoading } from "components/common";
import Routes from "common/router";
import { RootState } from "store/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

function App() {
  // const dispatch = useDispatch();
  const { loading, theme } = useSelector((state: RootState) => state.ui);

  // const toggleTheme = () => {
  //   const currentTheme = theme;
  //   if (currentTheme === "dark") {
  //     dispatch(setUI({ theme: "light" }));
  //   } else {
  //     dispatch(setUI({ theme: "dark" }));
  //   }
  // };

  return (
    <div className={`${theme} font-costum text-palette-text-lightDark`}>
      <div className="app-outer-background">
        <Routes />
        {loading && <FullScreenLoading />}
      </div>
      {/* <button onClick={toggleTheme} className="fixed bottom-0 left-0 w-10 h-10">
        Toggle
      </button> */}
      <ToastContainer rtl limit={1} />
    </div>
  );
}

export default App;
