import { addOfferType } from 'api'
import { CusButton, CusInput } from 'common/modules'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

type props = {
    handleAddNewItem: (type: "distance" | "types", newItem: number | string) => void
}

const AddAdsTypeForm: FC<props> = ({ handleAddNewItem }) => {

    const [loading, setLoading] = useState(false)

    const { register, handleSubmit } = useForm<{ offerType: string }>()

    const onSubmit = (data: { offerType: string }) => {
        setLoading(true)
        addOfferType(data).then((res) => {
            if (res.success) {
                handleAddNewItem("types", data.offerType)
            }
        }).finally(() => setLoading(false))

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <CusInput regFunc={() => register("offerType")} placeHolder='نوع مورد نظر را وارد کنید.' />
            <CusButton loading={loading} className='w-full text-black mt-6' type='submit'>ثبت</CusButton>
        </form>
    )
}

export default AddAdsTypeForm