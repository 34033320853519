import { LabelIcon } from "assets";
import React, { FC } from "react";

type props = {
  title: string;
};

const PagesIcon: FC<props> = ({ title }) => {
  return (
    <div className="flex items-center gap-x-4">
      <div className="bg-[#FF00C7] w-10 h-10 flex justify-center items-center rounded-lg drop-shadow-lg">
        <LabelIcon className="w-6 h-6" />
      </div>
      <p>{title}</p>
    </div>
  );
};

export default PagesIcon;
