import React, { FC } from "react";

const GraphIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.842 4.79238C10.8963 4.91992 10.9293 5.05513 10.9399 5.19287L11.0033 10.1716L11.0331 12.6739C11.0208 12.9304 11.0472 13.1873 11.1113 13.4364C11.2854 13.9224 11.7501 14.2506 12.2748 14.2581L20.2801 14.1709C20.6258 14.184 20.9524 14.3314 21.1879 14.5806C21.3841 14.7882 21.5037 15.0524 21.5307 15.331L21.5357 15.4995C20.9566 20.0524 17.3918 23.682 12.7768 24.4176C8.16181 25.1533 3.59758 22.8195 1.56217 18.6834C0.975869 17.482 0.639804 16.1783 0.573705 14.8488C0.537326 14.4544 0.533454 14.0577 0.562126 13.6627C0.821973 8.76184 4.54253 4.7153 9.48316 3.96007C10.0761 3.90056 10.6355 4.2432 10.842 4.79238Z"
        fill="#10B32A"
      />
      <path
        opacity="0.4"
        d="M14.3298 0.710704C19.786 1.14736 24.1641 5.32646 24.7597 10.6666L24.7491 10.7052L24.7219 10.7606L24.7168 10.9167C24.693 11.1225 24.6025 11.3169 24.4563 11.4699C24.3041 11.6293 24.1023 11.7325 23.8846 11.7649L23.7522 11.7758L14.5165 11.8707C14.2087 11.8842 13.9093 11.7689 13.6927 11.5535C13.5122 11.3739 13.4033 11.1389 13.3825 10.8909L13.2654 1.66731C13.2563 1.63565 13.2582 1.60196 13.2706 1.57147C13.2929 1.31862 13.4174 1.08525 13.6164 0.923505C13.8155 0.76176 14.0724 0.685119 14.3298 0.710704Z"
        fill="#10B32A"
      />
    </svg>
  );
};

export default GraphIcon;
