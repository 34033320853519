import moment from "jalali-moment";
import { toJalaali } from 'jalaali-js';

export function formatDate(inputDate?: string): string {
    // Split the input date by '-'
    const parts = inputDate?.split('-');

    // Extract year, month, and day
    const year = parts?.[0];
    const month = parts?.[1]?.length === 1 ? '0' + parts[1] : parts?.[1];
    const day = parts?.[2]?.length === 1 ? '0' + parts[2] : parts?.[2];
    console.log(month)
    // Return the formatted date
    return `${year}-${month}-${day}`;
}

export function normalizeTimeFormat(time: string): string {
    // Split the time string by the colon
    const parts = time.split(':');

    // Extract hours and minutes, pad with zeros if necessary
    const hours = parts[0]?.padStart(2, '0');
    const minutes = parts[1]?.padStart(2, '0');

    // Return the normalized time in "HH:MM" format
    return `${hours}:${minutes}`;
}

export const persianDateParser = (date?: string) => {
    if (typeof date === "string") {
        return moment(date).locale("fa").format("YYYY/MM/DD")
    }
}

export const gregorianToShamsi = (date?: string): string => {
    if (date) {
        // Parse the input date
        const [year, month, day] = date?.split?.('-')?.map?.(Number);
        if (year && month && day) {
            // Convert to Shamsi (Jalali) date
            const { jy, jm, jd } = toJalaali?.(year, month, day);

            // Format the Shamsi date as "YYYY-MM-DD"
            const shamsiDate = `${jy}-${String(jm)?.padStart?.(2, '0')}-${String(jd)?.padStart?.(2, '0')}`;

            return shamsiDate;
        }
    }
    return ""
}


export function roundTimeString(time: string): string {
    // Ensure the input string has exactly 4 characters
    if (time.length !== 4) {
        throw new Error("Invalid time format");
    }

    // Parse hours and minutes from the string
    let hours = parseInt(time.substring(0, 2), 10);
    let minutes = parseInt(time.substring(2, 4), 10);

    // Round minutes to the nearest 30-minute interval
    if (minutes < 15) {
        minutes = 0;
    } else if (minutes < 45) {
        minutes = 30;
    } else {
        minutes = 0;
        hours++;
    }

    // Handle the case where hours become 24
    if (hours === 24) {
        hours = 0;
    }

    // Format the result as "HH:MM"
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

export const convertTimeTextToModifiedTimeString = (time: string, withSecond?: boolean) => {
    const firstPart = time.substring(0, 2)
    const secondPart = time.substring(2)
    const second = withSecond ? "00" : "";
    return `${firstPart}:${secondPart}:${second}`;
}

export const convertDateStringToShamsiWithTime = (date: string) => {
    const persianDate = persianDateParser(date)
    const time = normalizeTimeFormat(date.split("T")[1].slice(0, 5))
    return `${persianDate} - ${time}`
}