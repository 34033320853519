import React, { FC } from "react";

const DashboardIcon: FC<React.SVGProps<SVGSVGElement>> = ({
  className,
  fill,
}) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0H12C10.9 0 10 0.9 10 2V16C10 17.1 10.9 18 12 18ZM6 18H2C0.9 18 0 17.1 0 16V11C0 9.9 0.9 9 2 9H6C7.1 9 8 9.9 8 11V16C8 17.1 7.1 18 6 18ZM0 5V2C0 0.9 0.9 0 2 0H6C7.1 0 8 0.9 8 2V5C8 6.1 7.1 7 6 7H2C0.9 7 0 6.1 0 5Z"
        fill={fill ?? "white"}
      />
    </svg>
  );
};

export default DashboardIcon;
