import React, { FC } from 'react'

const TickColoredIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2493 0C18.8542 0 21.2682 2.53091 21.2682 6.29537V14.9845C21.2682 18.7372 18.8542 21.2682 15.2493 21.2682H6.02952C2.42457 21.2682 0 18.7372 0 14.9845V6.29537C0 2.53091 2.42457 0 6.02952 0H15.2493ZM15.0791 7.44385C14.7176 7.0823 14.1221 7.0823 13.7605 7.44385L9.36862 11.8357L7.50766 9.97476C7.1461 9.61321 6.55059 9.61321 6.18903 9.97476C5.82747 10.3363 5.82747 10.9212 6.18903 11.2934L8.71994 13.8137C8.90072 13.9944 9.13467 14.0795 9.36862 14.0795C9.61321 14.0795 9.84716 13.9944 10.0279 13.8137L15.0791 8.76248C15.4407 8.40092 15.4407 7.81605 15.0791 7.44385Z" />
        </svg>
    )
}

export default TickColoredIcon