import React, { FC } from "react";

const PacketIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.3497 12.8981C20.3497 15.6212 18.1634 17.8271 15.4402 17.8368H15.4305H5.75805C3.04469 17.8368 0.829102 15.6408 0.829102 12.9176V12.9079C0.829102 12.9079 0.834958 8.58798 0.842766 6.41534C0.843742 6.00736 1.31224 5.77897 1.6314 6.03274C3.95044 7.87255 8.09759 11.2272 8.14931 11.2711C8.84229 11.8264 9.72072 12.1397 10.6187 12.1397C11.5166 12.1397 12.395 11.8264 13.088 11.2603C13.1397 11.2262 17.1942 7.97211 19.5484 6.10204C19.8685 5.84729 20.3389 6.07568 20.3399 6.48269C20.3497 8.63873 20.3497 12.8981 20.3497 12.8981Z"
        fill="white"
      />
      <path
        d="M19.8378 2.87774C18.9926 1.28487 17.3295 0.267844 15.4984 0.267844H5.75766C3.92663 0.267844 2.26348 1.28487 1.41824 2.87774C1.22889 3.23399 1.31868 3.67809 1.63394 3.9299L8.88095 9.72653C9.38848 10.1365 10.0034 10.3405 10.6183 10.3405C10.6222 10.3405 10.6251 10.3405 10.628 10.3405C10.631 10.3405 10.6349 10.3405 10.6378 10.3405C11.2527 10.3405 11.8676 10.1365 12.3751 9.72653L19.6221 3.9299C19.9374 3.67809 20.0272 3.23399 19.8378 2.87774Z"
        fill="white"
      />
    </svg>
  );
};

export default PacketIcon;
