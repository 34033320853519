export const RestaurantsCards = [
    {
        id: 1,
        title: "20 رستوران با بیشترین فروش",
        type: "mostsale",
    },
    {
        id: 2,
        title: "انواع رستوران با بیشترین فروش",
        type: "mostsalebycategory",
    },
    {
        id: 3,
        title: "بیشترین مشتری جذب شده",
        type: "mostnewcustomer",
    },
    {
        id: 4,
        title: "بیشترین مشتری ثبت نام شده",
        type: "mostregistercustomer",
    },
    {
        id: 5,
        title: "تعداد اشتراک جدید",
        type: "subscriptiontrend",
    },
    {
        id: 6,
        title: "بیشترین تعداد تراکنش سالانه",
        type: "mostpaymentcount",
    },
    {
        id: 7,
        title: "20 تا محبوب ترین زیردسته",
        type: "mostdesiredsubcats",
    },
    {
        id: 8,
        title: "روند ثبت کامنت ",
        type: "commenttrend",
    },
]

export const OffersCards = [
    {
        id: 9,
        title: "بیشترین تبلیغات بازدید شده ",
        type: "mostseenoffers",
    },
    {
        id: 10,
        title: "روند ثبت تبلیغات",
        type: "offertrend",
    },
    {
        id: 11,
        title: "تفکیک زمان تبلیغات",
        type: "offertimedivision",
    },
    {
        id: 12,
        title: "20 رستروان با بیشترین تبلیغ",
        type: "mostpublishedoffers",
    },
    {
        id: 13,
        title: "20 رستوران با بیشترین تخفیف",
        type: "mostpublisheddiscounts",
    },
    {
        id: 14,
        title: "سرانه پیشنهادات به ازای رستوران",
        type: "offerAverageTrend",
    },
    {
        id: 15,
        title: "سرانه کامن به ازای هر رستوران",
        type: "commentaveragetrend",
    },
    {
        id: 16,
        title: "گزارش تفکیکی نوع تبلیغات",
        type: "mostpublishedoffertypes",
    },
]