import React, { FC, useEffect, useState } from 'react'
import { PenOutline } from 'assets'
import { CusButton, CusDropdown, CusInput, ImageUploader } from 'common/modules'
import { CategoryType, SubCategoryType } from 'common/types'
import { useForm } from 'react-hook-form'
import { addNewSubCategory, updateSubCategory } from 'api'

type props = {
  categories?: CategoryType[]
  item: SubCategoryType
  handleUpdate?: (type: string, value: any, newProperties: any) => Promise<void>
  handleAddNewItem: (type: string, newItem: any) => Promise<void>
}

const RestaurantCategoryForm: FC<props> = ({ item, handleUpdate, handleAddNewItem, categories }) => {

  const { register, handleSubmit, watch, setValue } = useForm<{ category: string, subCategory: string, persianName?: string, imageLogo: File | string }>()

  const [selectedCat, setSelectedCat] = useState<any>()

  const [loading, setLoading] = useState<boolean>(false)

  const imagesGen = () => {
    const imageUrl = watch("imageLogo")
    if (typeof imageUrl === "string") {
      return [imageUrl]
    }
  }

  useEffect(() => {
    setValue("category", selectedCat?.value ?? "")
    setValue("persianName", selectedCat?.name)
  }, [selectedCat])


  const images = imagesGen()

  useEffect(() => {
    if (item) {
      setValue("imageLogo", item?.imageLogo)
      setValue("subCategory", item?.name)
      setValue("category", item?.category)
      setValue("persianName", item?.persianName)
      const defaultCat = categories?.filter((cat) => cat.name === item.category)[0]
      setSelectedCat({ name: defaultCat?.persianName, value: defaultCat?.name })
    }
  }, [item])

  const onSubmit = (data: { category: string, subCategory: string, imageLogo: File | string }) => {
    setLoading(true)
    const formdata = new FormData()
    formdata.append("imageLogo", data.imageLogo)

    if (item) {
      formdata.append("name", data.subCategory)
      //update sub category
      updateSubCategory(item.name, formdata).then((res) => {
        if (res.success) {
          handleUpdate?.("subCat", item._id, res.data)
        }
      }).finally(() => setLoading(false))
      return
    }

    // add item
    formdata.append("category", data.category)
    formdata.append("subCategory", data.subCategory)
    addNewSubCategory(formdata).then((res: any) => {
      if (res.success) {
        const addedItem: SubCategoryType = { ...res.data, persianName: res.data.catPersianName }
        handleAddNewItem("subCat", addedItem)
      }
    }).finally(() => setLoading(false))
    // handleAddNewItem("subCat",res.data)

  }
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className='flex flex-col gap-y-2'>
        <CusDropdown
          label='نوع رستوران'
          selected={selectedCat}
          disabled={Boolean(item)}
          onChange={setSelectedCat}
          items={categories?.map((item) => (
            { name: item.persianName, value: item.name }
          ))}
        />
        <CusInput
          regFunc={() => register("subCategory")}
          leftIcon={<PenOutline />}
          inputStyle={{ border: 0 }}
          placeHolder='زیر دسته'
        />
        <ImageUploader
          style={{ width: "100%", height: "200px" }}
          defaultImageUrls={images}
          onUpload={(file) => setValue("imageLogo", file)}
        />
        <CusButton loading={loading} onClick={handleSubmit(onSubmit)}>ثبت</CusButton>
      </div>
    </form>
  )
}

export default RestaurantCategoryForm