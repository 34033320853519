import { CusInput } from 'common/modules'
import { OrderType } from 'common/types/orders'
import React, { FC } from 'react'

type props = {
    order?: OrderType
}

const RestaurantOrdersDetail: FC<props> = ({ order }) => {
    return (
        <div className='flex flex-col justify-between w-[98%] mx-auto text-sm'>
            <div className='absolute top-0 left-0 right-0 col-span-full bg-palette-background-default p-4 rounded-t-lg'>
                <div className='flex justify-between w-11/12 mx-auto'>
                    <p>نام غذا</p>
                    <p>تعداد</p>
                    <p>مبلغ</p>
                </div>
            </div>
            <div className='h-[300px] overflow-y-scroll -mx-4'>
                <div className='pt-10 px-2 grid grid-cols-3'>
                    {
                        order?.orderFullDetails?.items?.map?.((item) => (
                            <>
                                <p>{item?.name}</p>
                                <p className='mr-4 text-center'>{item?.count}</p>
                                <p className='text-end'>{item?.price}</p>
                            </>
                        ))
                    }
                </div>
                <hr className='my-2' />
                <div className='px-2'>
                    <CusInput disabled ibName='توضیحات' value={order?.note} />
                    <div className='flex gap-x-4'>
                        <p>شماره میز</p>
                        <p>{order?.tableNumber}</p>
                    </div>
                    <hr className='my-2' />
                    <div>
                        <div className='flex justify-between'>
                            <p>مبلغ خرید</p>
                            <p>{order?.orderCost}</p>
                        </div>
                        <p>تخفیفات</p>
                        <div className='flex justify-between'>
                            <p>تخفیفات آیتم منو</p>
                            <p></p>
                        </div>
                        <div className='flex justify-between'>
                            <p>تخفیف مشتری VIP</p>
                            <p></p>
                        </div>
                        <div className='flex justify-between'>
                            <p>تخفیف مناسبت</p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-flbite-bg rounded-lg px-4 py-2 flex justify-between absolute bottom-4 left-4 right-4'>
                <p>مجموع کل (ارزش افزوده)</p>
                <p>{order?.orderFullDetails.overallAfterTax}</p>
            </div>
        </div>
    )
}

export default RestaurantOrdersDetail