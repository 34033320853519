import React, { FC } from 'react'

const PaperColoredIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75247 0C10.0099 0 10.2079 0.21 10.2079 0.46V3.68C10.2079 5.51 11.703 7.01 13.5149 7.02C14.2673 7.02 14.8614 7.03 15.3168 7.03L15.4855 7.02926C15.7899 7.02694 16.1997 7.02 16.5545 7.02C16.802 7.02 17 7.22 17 7.47V15.51C17 17.99 15.0099 20 12.5545 20H4.67327C2.09901 20 0 17.89 0 15.29V4.51C0 2.03 2 0 4.46535 0H9.75247ZM10.8119 12.9H5.42574C5.0198 12.9 4.68317 13.23 4.68317 13.64C4.68317 14.05 5.0198 14.39 5.42574 14.39H10.8119C11.2178 14.39 11.5545 14.05 11.5545 13.64C11.5545 13.23 11.2178 12.9 10.8119 12.9ZM8.77228 7.9H5.42574C5.0198 7.9 4.68317 8.24 4.68317 8.65C4.68317 9.06 5.0198 9.39 5.42574 9.39H8.77228C9.17822 9.39 9.51485 9.06 9.51485 8.65C9.51485 8.24 9.17822 7.9 8.77228 7.9ZM11.6507 0.906C11.6507 0.475 12.1685 0.261 12.4646 0.572C13.5349 1.696 15.4051 3.661 16.4507 4.759C16.7398 5.062 16.5279 5.565 16.1111 5.566C15.2972 5.569 14.3378 5.566 13.6477 5.559C12.5527 5.559 11.6507 4.648 11.6507 3.542V0.906Z" />
        </svg>
    )
}

export default PaperColoredIcon