import React from "react";
import { MenuOffLogo } from "assets/logos";
import DishPicture from "assets/pictures/login/DishPicture";
import { LoginCard } from "components";

const RootPage = () => {
  return (
    <div className="h-screen w-screen flex justify-end relative overflow-hidden">
      <MenuOffLogo className="fixed top-0 left-0 z-50" />
      <DishPicture className="absolute left-0 -bottom-5 h-[calc(100%+20px)] -z-10" />
      <LoginCard className="absolute w-96 h-[500px] top-1/2 -translate-y-1/2 right-[15%]" />
      <div className="bg-palette-background-default h-full w-1/4 rounded-l-[48px]" />
    </div>
  );
};

export default RootPage;
