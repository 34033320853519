import AxiosInstance from "IAxios/AxiosInstance";
import { PagintaionType } from "common/types";
import { DiscountType } from "common/types/discounts";
import { MarketingSearchTermsType } from "pages/dashboard/MarketingPage";
import { toast } from "react-toastify";

export interface DiscountsAPIType extends PagintaionType {
  data?: DiscountType[]
}

export const getAllDiscounts = (searchTerms?: MarketingSearchTermsType) =>
  AxiosInstance.get<DiscountsAPIType>("discounts/", { params: { ...searchTerms, limit: 10, page: searchTerms?.page } })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));