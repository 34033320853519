import React, { FC } from "react";

interface props extends React.SVGProps<SVGSVGElement> {
  onClick: () => void;
}

const HamburgerMenuIcon: FC<props> = ({ className, onClick }) => {
  return (
    <button onClick={onClick}>
      <svg
        className={className}
        width="28"
        height="16"
        viewBox="0 0 28 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.874977 1.74999L27.125 1.74999C27.608 1.74999 27.9999 1.35796 27.9999 0.875011C27.9999 0.391991 27.6079 3.29311e-05 27.125 3.28889e-05L0.874977 3.05941e-05C0.391958 3.05518e-05 -3.42724e-08 0.392062 -7.6493e-08 0.875008C-1.18714e-07 1.35796 0.39203 1.74999 0.874977 1.74999Z"
          fill="#1D1D1D"
        />
        <path
          d="M27.125 7.04727L0.874977 7.04727C0.391958 7.04727 -3.42723e-08 7.4393 -7.64928e-08 7.92225C-1.18713e-07 8.40519 0.39203 8.79722 0.874977 8.79722L27.125 8.79722C27.608 8.79722 27.9999 8.40519 27.9999 7.92225C27.9999 7.4393 27.608 7.04727 27.125 7.04727Z"
          fill="#1D1D1D"
        />
        <path
          d="M27.125 14.0945L9.62498 14.0945C9.14196 14.0945 8.75 14.4866 8.75 14.9695C8.75 15.4525 9.14203 15.8445 9.62498 15.8445L27.125 15.8445C27.608 15.8445 27.9999 15.4525 27.9999 14.9695C28 14.4865 27.608 14.0945 27.125 14.0945Z"
          fill="#1D1D1D"
        />
      </svg>
    </button>
  );
};

export default HamburgerMenuIcon;
