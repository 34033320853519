import React from "react";

const SubscriptionIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="white" />
      <path d="M12.75 10.75C12.25 10.625 11.75 10.375 11.375 10C11 9.875 10.875 9.5 10.875 9.25C10.875 9 11 8.625 11.25 8.5C11.625 8.25 12 8 12.375 8.125C13.125 8.125 13.75 8.5 14.125 9L15.25 7.5C14.875 7.125 14.5 6.875 14.125 6.625C13.75 6.375 13.25 6.25 12.75 6.25V4.5H11.25V6.25C10.625 6.375 10 6.75 9.5 7.25C9 7.875 8.625 8.625 8.75 9.375C8.75 10.125 9 10.875 9.5 11.375C10.125 12 11 12.375 11.75 12.75C12.125 12.875 12.625 13.125 13 13.375C13.25 13.625 13.375 14 13.375 14.375C13.375 14.75 13.25 15.125 13 15.5C12.625 15.875 12.125 16 11.75 16C11.25 16 10.625 15.875 10.25 15.5C9.875 15.25 9.5 14.875 9.25 14.5L8 15.875C8.375 16.375 8.75 16.75 9.25 17.125C9.875 17.5 10.625 17.875 11.375 17.875V19.5H12.75V17.625C13.5 17.5 14.125 17.125 14.625 16.625C15.25 16 15.625 15 15.625 14.125C15.625 13.375 15.375 12.5 14.75 12C14.125 11.375 13.5 11 12.75 10.75ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20.625C7.25 20.625 3.375 16.75 3.375 12C3.375 7.25 7.25 3.375 12 3.375C16.75 3.375 20.625 7.25 20.625 12C20.625 16.75 16.75 20.625 12 20.625Z" fill="black" />
    </svg>
  );
};

export default SubscriptionIcon;
