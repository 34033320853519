import React from "react";

const BuyIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1687_614)">
        <path
          opacity="0.4"
          d="M10.5038 19.8413C11.4977 19.8413 12.312 20.6556 12.312 21.6615C12.312 22.6555 11.4977 23.4698 10.5038 23.4698C9.4979 23.4698 8.68359 22.6555 8.68359 21.6615C8.68359 20.6556 9.4979 19.8413 10.5038 19.8413ZM23.9758 19.8413C24.9697 19.8413 25.784 20.6556 25.784 21.6615C25.784 22.6555 24.9697 23.4698 23.9758 23.4698C22.9699 23.4698 22.1556 22.6555 22.1556 21.6615C22.1556 20.6556 22.9699 19.8413 23.9758 19.8413Z"
          fill="#FA5F1C"
        />
        <path
          d="M4.93266 0.000114399L5.0547 0.00997582L7.91075 0.441079C8.31791 0.514127 8.61728 0.848231 8.65321 1.25538L8.88073 3.9378C8.91666 4.3222 9.22801 4.6096 9.61121 4.6096H25.7836C26.514 4.6096 26.993 4.86108 27.472 5.41193C27.9511 5.96279 28.0349 6.75314 27.9271 7.47045L26.7895 15.3261C26.5739 16.8362 25.2806 17.9486 23.7598 17.9486H10.695C9.10227 17.9486 7.78501 16.7272 7.65329 15.1477L6.55158 2.09364L4.74334 1.78229C4.26434 1.69846 3.92904 1.23143 4.01286 0.752431C4.09669 0.261452 4.56372 -0.0618747 5.0547 0.00997582L4.93266 0.000114399ZM21.8438 9.03919H18.5267C18.0237 9.03919 17.6285 9.43436 17.6285 9.93732C17.6285 10.4283 18.0237 10.8354 18.5267 10.8354H21.8438C22.3467 10.8354 22.7419 10.4283 22.7419 9.93732C22.7419 9.43436 22.3467 9.03919 21.8438 9.03919Z"
          fill="#FA5F1C"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1687_614"
          x="0"
          y="0"
          width="31.9688"
          height="31.4698"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.980392 0 0 0 0 0.372549 0 0 0 0 0.109804 0 0 0 0.34 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1687_614"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1687_614"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BuyIcon;
