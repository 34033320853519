import AxiosInstance from "IAxios/AxiosInstance";
import { BackendTemplate } from "api";
import { AdvertiseDistanceData, CategoryType, PagintaionType, RequestedSubcategoriesType, SubCategoryType, resFeature } from "common/types";
import { toast } from "react-toastify";

interface AddNewCatAPI extends BackendTemplate {
  data?: CategoryType
}

export const addNewCategoryApi = (data: FormData) =>
  AxiosInstance.post<AddNewCatAPI>("options/category", data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export const deleteCategory = (catEngName: string) =>
  AxiosInstance.delete(`options/category/${catEngName}`)
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export const updateCategory = (catEngName: string, data: FormData) =>
  AxiosInstance.put(`options/category/${catEngName}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))

export const addNewSubCategory = (data: FormData) =>
  AxiosInstance.post(`options/subcategory`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export const updateSubCategory = (lastSubCatName: string, data: FormData) =>
  AxiosInstance.put(`options/subcategory/${lastSubCatName}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))

export const deleteSubCategory = (data: { category: string, subCategory: string }) =>
  AxiosInstance.delete("options/subcategory", { data })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export const addRestaurantFeature = (data: resFeature) =>
  AxiosInstance.post("options/restaurants/features", data)
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export const deleteRestaurantFeature = (data: resFeature) =>
  AxiosInstance.delete("options/restaurants/features", { data })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export interface OwnersRequestedSubcategoriesAPIType extends PagintaionType {
  data?: RequestedSubcategoriesType[]
}

export const getOwnersRequestedSubcategories = (page?: number) =>
  AxiosInstance.get<OwnersRequestedSubcategoriesAPIType>("options/request/subcategory", { params: { page } })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export interface ChangeOwnerRequestedSubcategoryStatusDataType {
  name: string
  submit: string
  imageLogo?: File
  category: string
}

export const changeOwnerRequestedSubcategoryStatus = async (lastSubCatName: string, data: ChangeOwnerRequestedSubcategoryStatusDataType) =>
  AxiosInstance.put(`options/request/subcategory/${lastSubCatName}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));


export interface AddAdvertiseDistanceReturnedAPI extends BackendTemplate {

}

export const addAdvertiseDistance = (data: AdvertiseDistanceData) =>
  AxiosInstance.post("options/advertisedistance", data)
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))

export const deleteAdvertiseDistance = (data: AdvertiseDistanceData) =>
  AxiosInstance.delete("options/advertisedistance", { data })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))

export const deleteOfferType = (data: { offerType: string }) =>
  AxiosInstance.delete("options/offertype", { data })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))

export const addOfferType = (data: { offerType: string }) =>
  AxiosInstance.post("options/offertype", data)
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))