import React, { FC } from 'react'

const LocationColoredIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.85714 0C12.4714 0 14.9786 1.03852 16.8272 2.88709C18.6758 4.73566 19.7143 7.24287 19.7143 9.85714C19.7143 13.938 16.6038 18.2138 10.5143 22.781C10.3247 22.9231 10.0941 23 9.85714 23C9.62017 23 9.38958 22.9231 9.2 22.781C3.11048 18.2138 0 13.938 0 9.85714C0 7.24287 1.03852 4.73566 2.88709 2.88709C4.73566 1.03852 7.24287 0 9.85714 0ZM9.85714 6.57143C8.98572 6.57143 8.14998 6.9176 7.53379 7.53379C6.9176 8.14998 6.57143 8.98572 6.57143 9.85714C6.57143 10.7286 6.9176 11.5643 7.53379 12.1805C8.14998 12.7967 8.98572 13.1429 9.85714 13.1429C10.7286 13.1429 11.5643 12.7967 12.1805 12.1805C12.7967 11.5643 13.1429 10.7286 13.1429 9.85714C13.1429 8.98572 12.7967 8.14998 12.1805 7.53379C11.5643 6.9176 10.7286 6.57143 9.85714 6.57143Z" />
        </svg>
    )
}

export default LocationColoredIcon