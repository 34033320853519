import AxiosInstance from "IAxios/AxiosInstance";
import { OfferType, PagintaionType } from "common/types";
import { toast } from "react-toastify";

export interface OffersAPIType extends PagintaionType {
  data?: OfferType[]
}

export type GetAllOffersParamsType = {
  page?: number
  restaurantName?: string
  startDate?: string
  endDate?: string
}

export const getAllOffers = (params?: GetAllOffersParamsType) =>
  AxiosInstance.get<OffersAPIType>("offers", { params: { ...params, limit: 10 } })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));

export const updateOffer = (offerId: string, data: Partial<OfferType>) =>
  AxiosInstance.put(`offers/${offerId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error));