import React, { FC } from "react";
import InfoCard from "./InfoCard";

export interface CardType {
  title: string;
  headline?: string;
  icon?: any;
}

type props = {
  wrap?: boolean;
  cards?: CardType[];
  verticalCards?: boolean;
  xScroll?: boolean
};

const CardsContainer: FC<props> = ({ wrap, cards, verticalCards, xScroll }) => {

  return (
    <div
      dir="rtl"
      className={`${verticalCards ? "grid grid-cols-4 gap-4" : `flex gap-4 ${!xScroll ? "justify-between" : ""}`} ${wrap ? "flex-wrap" : "flex-nowrap"} ${xScroll ? "w-[90vw] mx-auto overflow-x-scroll pb-4 pl-40" : ""}`}
    >
      {cards?.map?.((card, i) => (
        <InfoCard
          index={i}
          card={card}
          key={card.title + i}
          className={xScroll ? "min-w-[185px]" : ""}
          isVertical={verticalCards}
        />
      ))}
    </div>
  );
};

export default CardsContainer;
