import React, { FC } from 'react'

const FilledPenIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg
            className={`fill-["#1D1D1D"] ${className}`}
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12.2704 11.5218C12.6728 11.5218 13 11.8533 13 12.2609C13 12.6693 12.6728 13 12.2704 13H8.14644C7.74409 13 7.41684 12.6693 7.41684 12.2609C7.41684 11.8533 7.74409 11.5218 8.14644 11.5218H12.2704ZM9.41045 0.504879L10.4757 1.35112C10.9126 1.69272 11.2038 2.14302 11.3034 2.6166C11.4184 3.13755 11.2958 3.64918 10.9509 4.09171L4.60518 12.2979C4.31395 12.6706 3.88477 12.8802 3.42493 12.888L0.895841 12.919C0.75789 12.919 0.642932 12.8259 0.612276 12.6939L0.0374822 10.2017C-0.0621487 9.74367 0.0374822 9.27009 0.328711 8.9052L4.82743 3.08243C4.90407 2.98926 5.04202 2.97451 5.13399 3.04361L7.02697 4.54976C7.1496 4.65069 7.3182 4.70504 7.49447 4.68175C7.87 4.63516 8.12291 4.29356 8.08459 3.92867C8.0616 3.74234 7.96964 3.58707 7.84701 3.47061C7.80869 3.43956 6.00767 1.99551 6.00767 1.99551C5.89271 1.90234 5.86972 1.73154 5.96169 1.61586L6.67443 0.691208C7.33353 -0.155035 8.48312 -0.232672 9.41045 0.504879Z" />
        </svg>
    )
}

export default FilledPenIcon