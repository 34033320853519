import React, { FC } from 'react'

const OperationIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5572 0C15.6156 0 17.67 2.09433 17.67 5.21102V14.1564C17.67 17.3004 15.6788 19.3451 12.5971 19.3645L5.11375 19.3675C2.05542 19.3675 0 17.2731 0 14.1564V5.21102C0 2.06612 1.99122 0.0223732 5.07289 0.003891L12.5563 0H12.5572ZM12.5572 1.45912L5.07775 1.46302C2.81319 1.47663 1.45912 2.87739 1.45912 5.21102V14.1564C1.45912 16.5056 2.82584 17.9083 5.11277 17.9083L12.5922 17.9054C14.8568 17.8918 16.2109 16.4891 16.2109 14.1564V5.21102C16.2109 2.86183 14.8451 1.45912 12.5572 1.45912ZM12.3696 13.1065C12.7723 13.1065 13.0991 13.4334 13.0991 13.8361C13.0991 14.2388 12.7723 14.5657 12.3696 14.5657H5.34633C4.94361 14.5657 4.61677 14.2388 4.61677 13.8361C4.61677 13.4334 4.94361 13.1065 5.34633 13.1065H12.3696ZM12.3696 9.03412C12.7723 9.03412 13.0991 9.36097 13.0991 9.76369C13.0991 10.1664 12.7723 10.4932 12.3696 10.4932H5.34633C4.94361 10.4932 4.61677 10.1664 4.61677 9.76369C4.61677 9.36097 4.94361 9.03412 5.34633 9.03412H12.3696ZM8.02597 4.97114C8.42868 4.97114 8.75553 5.29799 8.75553 5.7007C8.75553 6.10342 8.42868 6.43027 8.02597 6.43027H5.34604C4.94332 6.43027 4.61648 6.10342 4.61648 5.7007C4.61648 5.29799 4.94332 4.97114 5.34604 4.97114H8.02597Z" />
        </svg>
    )
}

export default OperationIcon