import { BuyIcon, ChartIcon, GraphIcon, ThreeUserIcon } from "assets";
import { CardType } from "components/CardsContainer/CardsContainer";

export const MAIN_PAGE_HEADER_CARDS: CardType[] = [
  {
    title: "مبلغ فروش",
    headline: undefined,
    icon: BuyIcon,
  },
  {
    title: "تعداد فروش",
    headline: undefined,
    icon: GraphIcon,
  },
  {
    title: "کاربران جدید",
    headline: undefined,
    icon: ThreeUserIcon,
  },
  {
    title: "مشتریان جدید",
    headline: undefined,
    icon: ChartIcon,
  },
  {
    title: "تبلیغات",
    headline: undefined,
    icon: ChartIcon,
  },
  {
    title: "نظرات",
    headline: undefined,
    icon: ChartIcon,
  },
];
