import React, { FC } from "react";
import { Avatar } from "flowbite-react";

type props = {
  headline?: string;
  Button?: JSX.Element; // ReactNode;
  name: string;
};

const AdminCard: FC<props> = ({ Button, headline, name }) => {
  return (
    <div dir="rtl" className="flex items-center gap-x-4">
      <Avatar rounded className="ring-1 ring-rose-500 p-1 rounded-full" />
      <div className="flex items-center gap-x-2">
        <div>
          <p className="text-sm">{name}</p>
          {headline && <p className="text-xs text-slate-600">{headline}</p>}
        </div>
        {Button && Button}
      </div>
    </div>
  );
};

export default AdminCard;
