import DashboardRoutes from 'common/router/DashboardRoutes'
import React, { FC } from 'react'
import Drawer from 'react-modern-drawer'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { RootState } from 'store/store'
import { motion } from "framer-motion";

import 'react-modern-drawer/dist/index.css'
import AdminCard from 'components/AdminCard/AdminCard'
import { Link } from 'react-router-dom'

type props = {
    isOpen: boolean
    toggleSidebar: () => void
}

const pages = DashboardRoutes.children;

const SidebarDrawer: FC<props> = ({ isOpen, toggleSidebar }) => {
    const { pathname } = useLocation();

    const { userName, role } = useSelector((state: RootState) => state.user)

    // const isSelected = (path: string) => {
    //   if (path === "" && pathname === "/dashboard") {
    //     return true;
    //   }
    //   return pathname.split("/").pop() === path;
    // };

    const thisPageIndex =
        pages?.findIndex((item) => item.path === pathname.split("/").pop()) ?? 0;

    const indicatorLoactionCoefficient = thisPageIndex === -1 ? 0 : thisPageIndex;



    return (
        <Drawer
            open={isOpen}
            direction='right'
            onClose={toggleSidebar}
            className='block md:hidden !bg-black !text-white pt-10 px-3 rounded-l-3xl'
        >
            <AdminCard name={userName ?? ""} headline={role} />
            <nav className="relative">
                <ul>
                    {pages?.map?.((page) => (
                        <li key={page.path} className={`my-2 px-2 rounded-lg`}>
                            <Link
                                dir="rtl"
                                className="flex items-center gap-x-6 w-full h-14"
                                to={page.path ?? "#"}
                            >
                                {page.hydrateFallbackElement}
                                <p>{page.id}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
                <motion.div
                    layout
                    layoutId="indicator"
                    initial={{ top: 0 }}
                    animate={{ top: indicatorLoactionCoefficient * 64 }}
                    className="absolute left-0 right-0 top-0 h-14 rounded-lg bg-palette-background-default/15"
                />
            </nav>
        </Drawer>
    )
}

export default SidebarDrawer