import { ChangeOwnerRequestedSubcategoryStatusDataType, changeOwnerRequestedSubcategoryStatus } from 'api'
import { CusButton, CusInput, ImageUploader } from 'common/modules'
import { RequestedSubcategoriesType } from 'common/types'
import { Select } from 'flowbite-react'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type props = {
    item: RequestedSubcategoriesType
    closeModal?: () => void
    handleUpdate?: (type: string, value: any, newProperties: any) => Promise<void>
}

const CategoriesRequestForm: FC<props> = ({ item, closeModal, handleUpdate }) => {

    const { register, handleSubmit, setValue } = useForm<ChangeOwnerRequestedSubcategoryStatusDataType>()

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setValue("name", item.subCategory)
        setValue("submit", item.status)
    }, [])

    const onChangeStatus = (data: ChangeOwnerRequestedSubcategoryStatusDataType) => {
        setLoading(true)
        changeOwnerRequestedSubcategoryStatus(item.subCategory, { ...data, category: item.category }).then((res) => {
            if (res.success) {
                handleUpdate?.("request", item.id, { status: data.submit, subCategory: data.name })
            }
        }).finally(() => setLoading(false))
    }

    return (
        <form onSubmit={handleSubmit(onChangeStatus)}>
            <div className='grid grid-cols-12 grid-rows-4 gap-x-4 items-center'>
                <div className='col-span-5'>
                    <CusInput value={item.category} disabled placeHolder='دسته' inputStyle={{ height: 65 }} />
                </div>
                <div className='col-span-5 row-start-2'>
                    <CusInput regFunc={() => register("name")} placeHolder='زیردسته' inputStyle={{ height: 65 }} />
                </div>
                <div className='col-span-5 row-start-3'>
                    {/* <CusInput
                        type='select'
                        regFunc={() => register("submit")}
                        inputStyle={{ height: 65, border: 0 }}
                        options={
                            [
                                { name: "درحال بررسی", value: "درحال بررسی" },
                                { name: "تایید", value: "تایید" },
                                { name: "عدم تایید", value: "عدم تایید" }
                            ]
                        }
                    /> */}
                    <Select {...register("submit")} style={{ height: 65, border: 0 }}>
                        {[
                            { name: "تایید", value: "تایید" },
                            { name: "عدم تایید", value: "عدم تایید" }
                        ].map((item) => (
                            <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                    </Select>
                </div>
                <div className='col-start-6 col-span-7 row-span-3'>
                    <ImageUploader
                        isMultiple={false}
                        onUpload={(file) => setValue("imageLogo", file)}
                        onDelete={() => setValue("imageLogo", undefined)}
                    />
                </div>
                <div className='col-span-7 col-start-6 row-span-1 flex justify-between'>
                    <CusButton onClick={closeModal} className='w-28 bg-slate-300 hover:enabled:bg-slate-400/50 text-black'>انصراف</CusButton>
                    <CusButton
                        type='submit'
                        loading={loading}
                        loadingWidth={16}
                        loadingColor='black'
                        className='w-28 text-black flex justify-center items-center'
                    >
                        ثبت
                    </CusButton>
                </div>
            </div>
        </form>
    )
}

export default CategoriesRequestForm