import React, { FC } from "react";
import DishPic from "./DishPicture.png";

const DishPicture: FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = ({ className, alt }) => {
  return <img className={className} alt={alt} src={DishPic} />;
};

export default DishPicture;
