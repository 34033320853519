import { updateOffer } from 'api'
import { CusButton, CusDropdown, CusInput, CusRangeSlider, CusTextArea, ImageUploader } from 'common/modules'
import { OfferType } from 'common/types'
import { convertTimeTextToModifiedTimeString, gregorianToShamsi } from 'common/utils'
import CusDatepicker from 'components/CusDatepicker/CusDatepicker'
import { Checkbox } from 'flowbite-react'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

type props = {
    item?: OfferType
    advTypes?: string[]
    advDistance?: number[]
    closeModal?: () => void
    handleUpdateArrays: (type: string, p: {
        key: string;
        value: string;
    }, newData: any) => void
}

const EditAdsForm: FC<props> = ({ item, advDistance, advTypes, closeModal, handleUpdateArrays }) => {

    const { register, setValue, watch, handleSubmit } = useForm<OfferType>({ defaultValues: item })
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (item) {
            const modifiedTimeArr = item.time.map((ti) => ({
                ...ti,
                startTime: ti.startTime === "undefined" ? "" : ti.startTime,
                endTime: ti.endTime === "undefined" ? "" : ti.endTime,
            }))
            const createdAtDate = new Date(item.createdAt)
            const expirationDate = new Date(item.expirationDate)

            setValue("createdAt", createdAtDate.toISOString().slice(0, 10))
            setValue("expirationDate", expirationDate.toISOString().slice(0, 10))
            setValue("time", modifiedTimeArr)
        }
    }, [item])


    const picGen = () => {
        const imageUrl = watch("picture")
        if (typeof imageUrl === "string") {
            return [imageUrl]
        }
    }

    // const timesArr = watch("time")?.map((t) => ({
    //     from: t.startTime.slice(0, 5).split(":"),
    //     to: t.endTime.slice(0, 5).split(":")
    // }))

    const onSubmit = (data: OfferType) => {

        const { expirationDate, picture, title, messageDescription } = data

        const dataToSend: Partial<OfferType> = {
            time: data.time.map((item) => {
                const st = item.startTime === "" ? undefined : item.startTime
                const et = item.endTime === "" ? undefined : item.endTime
                return {
                    // _id: item._id,
                    startTime: st,
                    endTime: et
                }
            }),
            expirationDate,
            picture,
            title,
            messageDescription
        }
        setLoading(true)
        updateOffer(data.id, dataToSend).then((res) => {
            if (res.success) {
                handleUpdateArrays("adv", { key: "id", value: res.data.id }, res.data)
                toast.success("با موفقیت ویرایش شد.")
                closeModal?.()
            }
        }).finally(() => setLoading(false))
    }

    const generateSliderTime = (index: number, what: "startTime" | "endTime") => {
        if (watch("time")?.[index]?.[what]) { }
        if (Boolean(watch?.("time")?.[index]?.[what]?.slice(0, 5).replace(":", ""))) {
            return watch?.("time")?.[index]?.[what]?.slice(0, 5).replace(":", "")
        } else return "0000"
    }

    const onSliderChange = (index: number, value: string[]) => {
        const newSelectedTime = value.map((v) => convertTimeTextToModifiedTimeString(v, true))
        let times = watch("time")
        times[index] = {
            ...times[index],
            startTime: newSelectedTime[0],
            endTime: newSelectedTime[1]
        }
        setValue("time", times)
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className='grid grid-cols-5 grid-rows-4 gap-4 h-[350px]'>
                <div className='col-span-2'>
                    <CusInput disabled regFunc={() => register("restaurantName")} placeHolder='نام رستوران' inputClassName='py-2' />
                </div>
                <div className='col-span-3'>
                    <CusInput regFunc={() => register("title")} placeHolder='عنوان' inputClassName='py-2' />
                </div>
                <div className='row-start-2 col-span-2'>
                    <CusDropdown
                        className='h-16'
                        label={watch("type") ?? "نوع"}
                        items={advTypes?.map((t) => ({ value: t, name: t })) ?? []}
                        onChange={(v) => setValue("type", v.name)}
                    />
                </div>
                <div className='row-start-3 col-span-2'>
                    <CusDatepicker
                        inputClassName='h-14'
                        defaultValue={gregorianToShamsi(watch("expirationDate"))}
                        placeholder='تاریخ'
                        onChange={(date, _, miladiDate) => {
                            setValue("createdAt", miladiDate ?? "")
                        }}
                    />
                </div>
                <div className='row-start-4 col-span-2'>
                    <CusDropdown
                        className='h-14 -mt-4'
                        items={advDistance?.map((t) => ({ value: t, name: t.toString() })) ?? []}
                        label={watch("distanceLimit").toString() ?? "شعاع تبلیغاتی"}
                        onChange={(item) => setValue("distanceLimit", Number(item.value))}
                    />
                </div>
                <div className='row-span-4 col-span-3'>
                    <ImageUploader defaultImageUrls={picGen()} />
                </div>
            </div>
            <div className='pt-4 -mt-10 w-[96%]'>
                <p className='mb-10'>انتخاب بازه زمانی</p>
                <div className='grid grid-cols-2 gap-x-32 gap-y-12'>
                    <div className='flex justify-between gap-x-4 items-center'>
                        <Checkbox
                            checked={watch("timePeriods").includes("صبح")}
                            className='focus:ring-palette-background-default text-palette-background-default w-5 h-5 rounded-full'
                            onChange={(e) => {
                                const prev = watch("timePeriods")
                                const newTimePeriods = [...prev]
                                if (e.target.checked) {
                                    newTimePeriods.push("صبح")
                                } else {
                                    newTimePeriods.splice(newTimePeriods.indexOf("صبح"), 1)
                                }
                                setValue("timePeriods", newTimePeriods)
                            }}
                        />
                        <CusRangeSlider
                            minTime='0700'
                            maxTime='1159'
                            minSlider={420} // 0700*60
                            maxSlider={720} // 12*60
                            className='w-3/4 flex-1'
                            min={generateSliderTime(0, "startTime")}
                            max={generateSliderTime(0, "endTime")}
                            onChange={(range) => onSliderChange(0, range)}
                        // range={[timesArr[0].from.join(""), timesArr[0].to.join("")]}
                        />
                    </div>
                    <div className='flex justify-between gap-x-4 items-center'>
                        <Checkbox
                            checked={watch("timePeriods").includes("ظهر")}
                            className='focus:ring-palette-background-default text-palette-background-default w-5 h-5 rounded-full'
                            onChange={(e) => {
                                const prev = watch("timePeriods")
                                const newTimePeriods = [...prev]
                                if (e.target.checked) {
                                    newTimePeriods.push("ظهر")
                                } else {
                                    newTimePeriods.splice(newTimePeriods.indexOf("ظهر"), 1)
                                }
                                setValue("timePeriods", newTimePeriods)
                            }}
                        />
                        <CusRangeSlider
                            maxTime='1559'
                            minTime='1200'
                            minSlider={720}
                            maxSlider={960}
                            className='w-3/4 flex-1'
                            max={generateSliderTime(1, "endTime")}
                            min={generateSliderTime(1, "startTime")}
                            onChange={(range) => onSliderChange(1, range)}
                        // range={[timesArr[1].from.join(""), timesArr[1].to.join("")]}
                        />
                    </div>
                    <div className='flex justify-between gap-x-4 items-center'>
                        <Checkbox
                            checked={watch("timePeriods").includes("عصر")}
                            className='focus:ring-palette-background-default text-palette-background-default w-5 h-5 rounded-full'
                            onChange={(e) => {
                                const prev = watch("timePeriods")
                                const newTimePeriods = [...prev]
                                if (e.target.checked) {
                                    newTimePeriods.push("عصر")
                                } else {
                                    newTimePeriods.splice(newTimePeriods.indexOf("عصر"), 1)
                                }
                                setValue("timePeriods", newTimePeriods)
                            }}
                        />
                        <CusRangeSlider
                            minTime='1600'
                            maxTime='1929'
                            minSlider={960}
                            maxSlider={1170}
                            className='w-3/4 flex-1'
                            max={generateSliderTime(2, "endTime")}
                            min={generateSliderTime(2, "startTime")}
                            onChange={(range) => onSliderChange(2, range)}
                        // range={[timesArr[2].from.join(""), timesArr[2].to.join("")]}
                        />
                    </div>
                    <div className='flex justify-between gap-x-4 items-center'>
                        <Checkbox
                            checked={watch("timePeriods").includes("شب")}
                            className='focus:ring-palette-background-default text-palette-background-default w-5 h-5 rounded-full'
                            onChange={(e) => {
                                const prev = watch("timePeriods")
                                const newTimePeriods = [...prev]
                                if (e.target.checked) {
                                    newTimePeriods.push("شب")
                                } else {
                                    newTimePeriods.splice(newTimePeriods.indexOf("شب"), 1)
                                }
                                setValue("timePeriods", newTimePeriods)
                            }}
                        />
                        <CusRangeSlider
                            minTime='1930'
                            maxTime='2359'
                            minSlider={1170}
                            maxSlider={1440}
                            className='w-3/4 flex-1'
                            max={generateSliderTime(3, "endTime")}
                            min={generateSliderTime(3, "startTime")}
                            onChange={(range) => onSliderChange(3, range)}
                        // range={[timesArr[3].from.join(""), timesArr[3].to.join("")]}
                        />
                    </div>
                </div>
            </div>
            <CusTextArea
                rows={6}
                className='mt-10'
                placeHolder='توضیحات'
                inputStyle={{ border: 0 }}
                regFunc={() => register("messageDescription")}
            />
            <div className='bg-modalBgfooter/30 p-6 rounded-t-lg flex justify-between -mx-6 mt-6 -mb-6'>
                <button>راهنما</button>
                <div className='flex gap-x-4'>
                    <CusButton
                        onClick={closeModal}
                        loadingWidth={12}
                        loading={loading}
                        loadingColor='#383838'
                        className='bg-slate-300 w-24 text-black enabled:hover:bg-slate-300/80'
                    >
                        انصراف
                    </CusButton>
                    <CusButton
                        loading={loading}
                        loadingWidth={12}
                        loadingColor='#383838'
                        className='text-black w-32'
                        onClick={handleSubmit(onSubmit)}
                    >
                        ذخیره
                    </CusButton>
                </div>
            </div>
        </form>
    )
}

export default EditAdsForm