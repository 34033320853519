interface GenericObject {
    [key: string]: any;
}

export const deleteObjectFromArray = (arr: GenericObject[], key: string, value: any): GenericObject[] => {
    return arr.filter(obj => obj[key] !== value);
}

export const updateObjectInArray = (
    arr: GenericObject[],
    key: string,
    value: any,
    newProperties: GenericObject
): GenericObject[] => {
    return arr.map(obj => {
        if (obj[key] === value) {
            return { ...obj, ...newProperties };
        }
        return obj;
    });
}

export const addObjectToArray = (arr: GenericObject[], newObj: GenericObject): GenericObject[] => {
    return [...arr, newObj];
}

export function objectToFormData(obj: Record<string, any>): FormData {
    const formData = new FormData();

    function appendFormData(data: any, parentKey?: string) {
        if (data === undefined) return
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                appendFormData(data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            formData.append(parentKey || '', data);
        }
    }

    appendFormData(obj);
    return formData;
}

export const extractNumbers = (arr: any[]) => {
    return arr.flatMap(obj => {
        return Object.values(obj).filter(value => typeof value === 'number');
    });
};

export const extractStringsDeep = (arr: Array<Record<string, any>>): string[] => {
    const result: string[] = [];

    const extractStrings = (obj: Record<string, any>) => {
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                result.push(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                extractStrings(obj[key]);
            }
        }
    };

    arr.forEach(item => extractStrings(item));
    return result;
};