import React, { FC, useState } from 'react'
import { CusButton, CusDropdown, CusImage, CusInput, CusModal, ImageUploader } from 'common/modules'
import { CompleteOnwerType, EquipmentsList, UpdateOwnerType } from 'common/types'
import { formatDate, persianDateParser } from 'common/utils'
import { Button, TableCell, TableRow } from 'flowbite-react'
import CusTable from 'components/CusTable/CusTable'
import { useForm } from 'react-hook-form'
import { updateOwnerInformation } from 'api'
import CusDatepicker from 'components/CusDatepicker/CusDatepicker'

type props = {
    owner?: CompleteOnwerType
    handleUpdate?: (type: string, updatedItemId: string, newValue: any) => void
}

const OnwersInformationForm: FC<props> = ({ owner, handleUpdate }) => {
    const [selectedEquipment, setSelectedEquipment] = useState<EquipmentsList>()

    const [melliImagePreview, setMelliImagePreview] = useState(owner?.melliImage)
    const [certPreview, setCertPreview] = useState(owner?.certificate)

    const [loading, setLoading] = useState<boolean>(false)

    const [uploadedImages, setUploadedImages] = useState<{ melliImage?: File, certificate?: File }>()

    const defaultValues: Partial<UpdateOwnerType> = {
        address: owner?.address,
        birthday: owner?.birthday,
        // certificate: owner?.certificate,
        email: owner?.email,
        family: owner?.family,
        isBanned: owner?.isBanned,
        // melliImage: owner?.melliImage,
        mphone: owner?.mphone,
        name: owner?.name,
        nationalCode: owner?.nationalCode,
        phone: owner?.phone,
    }

    const { register, setValue, watch } = useForm<UpdateOwnerType>({ defaultValues })

    const onSubmit = (data: UpdateOwnerType) => {
        const isBanned = typeof data.isBanned === "string" ? data.isBanned === "مجاز" ? false : true : data.isBanned
        setLoading(true)
        let dataToSend: UpdateOwnerType = { ...data, birthday: data?.birthday?.split?.("T")?.[0], isBanned }
        if (uploadedImages) {
            const { certificate, melliImage } = uploadedImages
            if (melliImage) {
                dataToSend = { ...dataToSend, melliImage }
            } else if (certificate) {
                dataToSend = { ...dataToSend, certificate }
            }
        }
        updateOwnerInformation(owner?._id, dataToSend).then((res) => {
            if (res.success && owner?._id) {
                handleUpdate?.("UpdateOwner", owner._id, res.data)
            }
        }).finally(() => setLoading(false))
    }

    const onChangeImage = (type: "melli" | "cert", event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0]
        if (file) {
            if (type === "melli") {
                setUploadedImages((prev) => ({ ...prev, melliImage: file }))
                setMelliImagePreview(URL.createObjectURL(file))
                return
            }
            if (type === "cert") {
                setUploadedImages((prev) => ({ ...prev, certificate: file }))
                setCertPreview(URL.createObjectURL(file))
                return
            }
        }
    }

    return (
        <>
            <form onSubmit={(e) => e.preventDefault()}>
                <div>
                    <p className='mb-4'>اطلاعات فردی</p>
                    <div className='grid grid-cols-3 gap-4 w-11/12 mx-auto'>
                        <div className='flex gap-x-2'>
                            <p className=''>نام : </p>
                            <CusInput regFunc={() => register("name")} />
                        </div>
                        <div className='flex gap-x-2'>
                            <p className='w-20'>تاریخ تولد : </p>
                            {/* <p>{persianDateParser(owner?.birthday)}</p> */}
                            <CusDatepicker defaultValue={owner?.birthday?.split?.("T")?.[0]} onChange={(_, a, miladiDate) => setValue("birthday", formatDate(miladiDate))} />
                        </div>
                        <div className='flex gap-x-2'>
                            <p>وضعیت مالک : </p>
                            {/* <p className={`${owner?.isBanned ? "text-rose-600" : "text-lime-600"}`}>{owner?.isBanned ? "غیرمجاز" : "مجاز"}</p> */}
                            <CusDropdown
                                className='w-[100px]'
                                label={typeof watch("isBanned") === "boolean" ? watch("isBanned") ? "غیرمجاز" : "مجاز" : watch("isBanned")?.toString?.()}
                                items={[{ name: "مجاز", value: "مجاز" }, { name: "غیرمجاز", value: "غیرمجاز" }]} onChange={(selected) => setValue("isBanned", selected.value?.toString?.())}
                            />
                        </div>
                        <div className='flex gap-x-2'>
                            <p className='w-20'>نام خانوادگی : </p>
                            <CusInput regFunc={() => register("family")} />
                        </div>
                        <div className='flex gap-x-2'>
                            <p>کدملی : </p>
                            <CusInput regFunc={() => register("nationalCode")} />
                        </div>
                        <div className='flex gap-x-2'>
                            <p>آخرین لاگین : </p>
                            <p>{persianDateParser(owner?.lastestLogin)}</p>
                        </div>
                    </div>
                </div>
                <hr className='my-4' />
                <div>
                    <p className='mb-4'>اطلاعات تماس</p>
                    <div className='grid grid-cols-2 gap-x-6 gap-y-4 w-11/12 mx-auto'>
                        <div className='flex items-center justify-between gap-x-2'>
                            <p>شماره موبایل</p>
                            <CusInput regFunc={() => register("mphone")} />
                            <p />
                        </div>
                        <div className='flex items-center justify-between gap-x-2'>
                            <p>آدرس ایمیل</p>
                            <CusInput regFunc={() => register("email")} />
                            <p className={`${owner?.isEmailConfirmed ? "text-lime-600" : "text-rose-600"}`}>{owner?.isEmailConfirmed ? "تایید شده" : "عدم تایید"}</p>
                        </div>
                        <div className='flex items-center justify-between gap-x-2'>
                            <p>شماره ثابت</p>
                            <CusInput regFunc={() => register("phone")} />
                            <p />
                        </div>
                        <div className='flex items-center justify-between gap-x-2'>
                            <p>آدرس منزل</p>
                            <CusInput regFunc={() => register("address")} />
                            <p />
                        </div>
                    </div>
                </div>
                <hr className='my-4' />
                <div>
                    <p className='mb-4'>مدارک هویتی</p>
                    <div className='w-3/4 mx-auto flex justify-between gap-x-6'>
                        <div className='flex gap-x-8 items-center'>
                            <p>عکس کارت ملی</p>
                            <button onClick={() => document.getElementById("melli-image-input")?.click()}>
                                <CusImage className='w-28 rounded-lg overflow-hidden' src={melliImagePreview} />
                            </button>
                            <input id='melli-image-input' type="file" className='hidden' onChange={(e) => onChangeImage("melli", e)} />
                        </div>
                        <div className='flex gap-x-8 items-center'>
                            <p>عکس مجوز</p>
                            <button onClick={() => document.getElementById("cert-image-input")?.click()}>
                                <CusImage className='w-28 rounded-lg overflow-hidden' src={certPreview} />
                            </button>
                            <input id='cert-image-input' type="file" className='hidden' onChange={(e) => onChangeImage("cert", e)} />
                        </div>
                    </div>
                </div>
                <hr className='my-6' />
                <div>
                    <p className='mb-4'>تجهیزات و سرویس</p>
                    <div>
                        <CusTable
                            stickyHeader
                            noItem={owner?.equipmentsLog?.list?.length === 0 || !owner?.equipmentsLog}
                            headers={["ردیف", "شناسه سفارش", "تاریخ سفارش", "مبلغ کل", "هزینه حمل", "جزپیات"]}
                            contents={
                                owner?.equipmentsLog?.list?.map?.((eItem) => (
                                    <TableRow>
                                        <TableCell>{eItem?.orderId}</TableCell>
                                        <TableCell>{eItem?.orderId}</TableCell>
                                        <TableCell>{eItem?.purchaseDate}</TableCell>
                                        <TableCell>{eItem?.totalPrice}</TableCell>
                                        <TableCell>{eItem?.shippingCost}</TableCell>
                                        <TableCell>
                                            <button onClick={() => setSelectedEquipment(eItem)}>جزئیات</button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        />
                    </div>
                </div>
                <hr className='my-6' />
                <div>
                    <p className='mb-4'>پیام ها</p>
                    <div>
                        <CusTable
                            wrapperClassName='h-[25vh]'
                            stickyHeader
                            noItem={owner?.messages?.length === 0}
                            headers={["عنوان", "توضیحات", "وضعیت"]}
                            contents={owner?.messages?.map((msg) => (
                                <TableRow>
                                    <TableCell>{msg?.data?.title}</TableCell>
                                    <TableCell className='text-[#929292]'>{msg?.text}</TableCell>
                                    <TableCell>{msg?.isSeen ? "دیده شده" : "دیده نشده"}</TableCell>
                                </TableRow>
                            ))}
                        />
                    </div>
                </div>
                <div className='flex justify-end mt-6'>
                    <CusButton
                        loading={loading}
                        loadingColor='black'
                        onClick={() => onSubmit(watch())}
                        className='bg-palette-background-default text-black px-6 hover:enabled:bg-palette-background-default/75'
                    >
                        ثبت
                    </CusButton>
                </div>
            </form>
            {Boolean(selectedEquipment) &&
                <CusModal
                    isSecond
                    title={`حزئیات سرویس ${selectedEquipment?.orderId}`}
                    isOpen={Boolean(selectedEquipment)}
                    onClose={() => setSelectedEquipment(undefined)}
                    MainCompoenent={
                        <div>
                            <CusTable
                                stickyHeader
                                headers={["ردیف", "نام تجهیز", "تعداد", "تعداد رایگان", "قیمت واحد"]}
                                contents={
                                    selectedEquipment?.items.map((item, i) => (
                                        <TableRow>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{item?.name}</TableCell>
                                            <TableCell>{item?.count}</TableCell>
                                            <TableCell>{item?.freeCount}</TableCell>
                                            <TableCell>{item?.unitPrice}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            />
                        </div>
                    }
                />}
        </>
    )
}

export default OnwersInformationForm