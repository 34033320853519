import React from "react";

const CustomerIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 16C15.1 16 15.99 16.9 15.99 18C15.99 19.1 15.1 20 14 20C12.9 20 12 19.1 12 18C12 16.9 12.9 16 14 16ZM20 0V2H18L14.4 9.59L15.75 12.04C15.91 12.32 16 12.65 16 13C16 14.1 15.1 15 14 15H2V13H13.58C13.72 13 13.83 12.89 13.83 12.75L13.8 12.63L12.9 11H5.45C4.7 11 4.04 10.59 3.7 9.97L0.120001 3.48C0.0374947 3.32739 -0.00401688 3.15598 -0.000476837 2.98253C0.0030632 2.80908 0.0515347 2.63951 0.1402 2.49039C0.228865 2.34127 0.354694 2.2177 0.505394 2.13175C0.656094 2.04579 0.826511 2.0004 1 2H15.79L16.73 0H20ZM4 16C5.1 16 5.99 16.9 5.99 18C5.99 19.1 5.1 20 4 20C2.9 20 2 19.1 2 18C2 16.9 2.9 16 4 16Z"
        fill="white"
      />
    </svg>
  );
};

export default CustomerIcon;
