import React from "react";
import { Textarea } from "flowbite-react";

type props = {
  ibName?: string;
  regFunc?: Function;
  className?: string;
  placeHolder?: string;
  rows?: number;
  inputStyle?: React.CSSProperties;
  disabled?: boolean
  errorMsg?: string
};

const CusTextArea = ({
  ibName,
  regFunc,
  className,
  placeHolder,
  rows,
  inputStyle,
  disabled,
  errorMsg
}: props) => {
  return (
    <div className={className}>
      {ibName && (
        <label
          htmlFor="first_name"
          className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
        >
          {ibName}
        </label>
      )}
      <Textarea
        rows={rows}
        {...regFunc?.()}
        style={inputStyle}
        disabled={disabled}
        className="resize-none"
        placeholder={placeHolder}
      />
      {errorMsg && <p className="text-xs text-red-500">{errorMsg}</p>}
    </div>
  );
};

export default CusTextArea;
