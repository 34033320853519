import AxiosInstance from "IAxios/AxiosInstance";
import { PagintaionType } from "common/types";
import { DiscountCodeFormType, DiscountCodeType } from "common/types/discounts";
import { toast } from "react-toastify";

export interface AllDiscountCodesAPI extends PagintaionType {
    data?: DiscountCodeType[]
}

export interface GetAllDiscountCodesSearchTerms {
    code?: string
    startDate?: string
    endDate?: string
}

export const getAllDiscountCodes = (page: number, searchTerms?: GetAllDiscountCodesSearchTerms) =>
    AxiosInstance.get<AllDiscountCodesAPI>("discountcodes", { params: { page, limit: 10, ...searchTerms } })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error));

interface CreateDiscountCodeAPI extends PagintaionType {
    data?: DiscountCodeType
}

export const createDiscountCode = (data: DiscountCodeFormType) =>
    AxiosInstance.post<CreateDiscountCodeAPI>("discountcodes", data)
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error));

export const deleteDiscountCode = async (data: DiscountCodeType) =>
    AxiosInstance.delete(`discountcodes/${data.id}`)
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error));