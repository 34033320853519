import React, { FC } from 'react'

const CircleChartIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8172 4.7411C10.8709 4.86728 10.9036 5.00106 10.9141 5.13732L10.9768 10.0629L11.0063 12.5385C10.9941 12.7923 11.0202 13.0465 11.0836 13.2929C11.2559 13.7737 11.7156 14.0984 12.2347 14.1058L20.1545 14.0195C20.4965 14.0325 20.8196 14.1783 21.0526 14.4248C21.2467 14.6302 21.365 14.8916 21.3918 15.1672L21.3967 15.334C20.8238 19.8383 17.2971 23.4291 12.7314 24.1569C8.16564 24.8847 3.65016 22.5758 1.6365 18.4839C1.05646 17.2953 0.723984 16.0055 0.658591 14.6902C0.6226 14.3 0.618769 13.9075 0.647135 13.5168C0.904207 8.6682 4.58502 4.66485 9.47288 3.91768C10.0595 3.8588 10.6129 4.19779 10.8172 4.7411Z" fill="#10B32A" />
            <path opacity="0.4" d="M14.2679 0.703022C19.6658 1.13502 23.9971 5.26951 24.5864 10.5526L24.5759 10.5908L24.549 10.6457L24.5439 10.8001C24.5204 11.0037 24.4308 11.196 24.2862 11.3474C24.1356 11.5051 23.936 11.6072 23.7206 11.6393L23.5897 11.65L14.4526 11.7439C14.1481 11.7573 13.8519 11.6432 13.6376 11.4301C13.459 11.2524 13.3513 11.0199 13.3307 10.7745L13.2149 1.64942C13.2059 1.6181 13.2077 1.58477 13.22 1.55461C13.242 1.30445 13.3653 1.07357 13.5622 0.913552C13.7591 0.753533 14.0133 0.67771 14.2679 0.703022Z" fill="#10B32A" />
        </svg>
    )
}

export default CircleChartIcon