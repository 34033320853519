import React, { FC } from 'react'

const UploadIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="79" height="64" viewBox="0 0 79 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M64.1875 25.1077V24.6154C64.1875 10.8308 53.325 0 39.5 0C27.1562 0 16.7875 8.86154 15.3062 21.1692C6.41875 24.1231 0 32 0 41.8461C0 54.1538 9.875 64 22.2187 64C30.6125 64 51.35 64 59.25 64C70.1125 64 79 55.1385 79 44.3077C79 34.9538 72.5812 27.5692 64.1875 25.1077Z" fill="black" />
            <path d="M44.4375 39.3847V54.1539H34.5625V39.3847H19.75L39.5 19.6924L59.25 39.3847H44.4375Z" fill="white" />
        </svg>

    )
}

export default UploadIcon