import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminType } from "common/types";

const initialUserState: AdminType = {};

const userSlice = createSlice({
  name: "user",
  initialState: { ...initialUserState },
  reducers: {
    setUser(state, action: PayloadAction<AdminType>) {
      return (state = action.payload);
    },
    removeUser(state) {
      return (state = initialUserState);
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
