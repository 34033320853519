import React from "react";

const RestaurantIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 2.5C4.2 2.5 4 2.8 4 3V5.5H3V3C3 2.7 2.8 2.5 2.5 2.5C2.2 2.5 2 2.8 2 3V5.5H1V3C1 2.7 0.8 2.5 0.5 2.5C0.2 2.5 0 2.8 0 3V6.3C0 7.2 0.7 7.9 1.5 8V15C1.5 15.6 1.9 16 2.5 16C3.1 16 3.5 15.6 3.5 15V8C4.3 7.9 5 7.2 5 6.3V3C5 2.8 4.8 2.5 4.5 2.5ZM6.5 3V9H7.5V15C7.5 15.6 7.9 16 8.5 16C9.1 16 9.5 15.6 9.5 15V0C7.8 0 6.5 1.3 6.5 3ZM13.5 2C12.1 2 11 3.5 11 5.3C10.9 6.5 11.5 7.6 12.5 8.3V15C12.5 15.6 12.9 16 13.5 16C14.1 16 14.5 15.6 14.5 15V8.3C15.5 7.6 16.1 6.5 16 5.3C16 3.5 14.9 2 13.5 2Z"
        fill="white"
      />
    </svg>
  );
};

export default RestaurantIcon;
