import React, { FC, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Define the custom icon
const customIcon = new L.Icon({
    iconUrl: `${process.env.PUBLIC_URL}/assets/placeholder.png`,
    iconSize: [25, 25], // Size of the icon
    // iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    // popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
    //   shadowUrl: 'path/to/your/marker-shadow.png', // Optional: Shadow image
    // shadowSize: [41, 41] // Size of the shadow
});

type CusMapProps = {
    latLng: {
        lat: number,
        lng: number
    }
    onChange?: (position?: LatLngExpression) => void
}

const CustomLocationMap: FC<CusMapProps> = ({ latLng, onChange }) => {
    const { lat, lng } = latLng;
    const [position, setPosition] = useState<LatLngExpression>([lat, lng])

    useEffect(() => {
        onChange?.(position)
    }, [position])

    // Update marker position when user clicks on the map
    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                setPosition([e.latlng.lat, e.latlng.lng]);
            },
        });
        return (
            <Marker
                riseOnHover
                draggable={true}
                icon={customIcon}
                position={position}
                eventHandlers={{
                    dragend: (e) => {
                        const marker = e.target;
                        const newPosition = marker.getLatLng();
                        setPosition([newPosition.lat, newPosition.lng]); // Update position on drag
                    }
                }}
            />
        )
    }

    return (
        <MapContainer
            zoom={20}
            center={[lat, lng]}
            style={{ height: "100%", width: "100%" }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker />
        </MapContainer>
    );
};

export default CustomLocationMap;  
