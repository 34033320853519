import React, { FC } from 'react'

const DeleteIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className, fill = "#FF0000" }) => {
    return (
        <svg className={className} width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.08403 2.9765C7.17264 2.9765 7.25304 3.01517 7.31658 3.0805C7.3758 3.15028 7.40562 3.23695 7.39698 3.3285C7.39698 3.35872 7.1601 6.35425 7.02481 7.61512C6.94009 8.3889 6.44128 8.85867 5.69306 8.87156C5.11774 8.88445 4.55539 8.88889 4.00169 8.88889C3.41383 8.88889 2.83895 8.88445 2.28092 8.87156C1.55777 8.85422 1.05853 8.37601 0.978128 7.61512C0.838945 6.3498 0.606397 3.35872 0.602075 3.3285C0.597752 3.23695 0.627145 3.15028 0.686795 3.0805C0.74558 3.01517 0.830301 2.9765 0.919343 2.9765H7.08403ZM4.91766 0C5.31057 0 5.66155 0.27422 5.76313 0.665327L5.83575 0.989768C5.89453 1.25421 6.12362 1.44132 6.38729 1.44132H7.68317C7.85606 1.44132 8 1.58487 8 1.76754V1.93643C8 2.11465 7.85606 2.26264 7.68317 2.26264H0.317268C0.143938 2.26264 0 2.11465 0 1.93643V1.76754C0 1.58487 0.143938 1.44132 0.317268 1.44132H1.61314C1.87638 1.44132 2.10547 1.25421 2.16469 0.990212L2.23255 0.687104C2.33802 0.274219 2.68511 0 3.08234 0H4.91766Z" fill={fill} />
        </svg>

    )
}

export default DeleteIcon