import React, { FC } from "react";

const SearchIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0604 17.1616C15.0643 17.1616 19.1208 13.5437 19.1208 9.08081C19.1208 4.6179 15.0643 1 10.0604 1C5.05648 1 1 4.6179 1 9.08081C1 13.5437 5.05648 17.1616 10.0604 17.1616Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9266 19.3939L16 15"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
