import { OperationIcon } from "assets";
import { OfferType, PagintaionType } from "common/types";
import { persianDateParser } from "common/utils";
import CusTable from "components/CusTable/CusTable";
import { Pagination, TableCell, TableRow } from "flowbite-react";
import { openedModalType } from "pages/dashboard/MarketingPage";
import React, { FC } from "react";

type props = {
  offers?: OfferType[]
  setPage?: React.Dispatch<React.SetStateAction<number>>
  page: number
  pagesMeta?: PagintaionType
  setOpenedModal: React.Dispatch<React.SetStateAction<openedModalType | undefined>>
}

const AdvertisementList: FC<props> = ({ offers, setPage, page, pagesMeta, setOpenedModal }) => {
  const pagesCount = pagesMeta && Math.ceil(pagesMeta?.totalCount / 10)

  return (
    <div>
      <CusTable
        noItem={offers?.length === 0}
        stickyHeader
        headers={[
          "نام رستوران",
          "عنوان تبلیغ",
          "نوع تبلیغ",
          "تاریخ",
          "بازه زمانی",
          "بازدید",
          "وضعیت",
          "عملیات",
        ]}
        contents={offers?.map((item) => (
          <TableRow key={item._id}>
            <TableCell>{item.restaurantName}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{item.type}</TableCell>
            <TableCell>{persianDateParser(item.createdAt)}</TableCell>
            <TableCell>{item.timePeriods.map((t) => <span key={t} className="mx-1">{t}</span>)}</TableCell>
            <TableCell>{item.seenNumber.count}</TableCell>
            <TableCell>{item.status}</TableCell>
            <TableCell>
              <button onClick={() => setOpenedModal({ title: "تبلیغات", item, id: 3 })}>
                <OperationIcon className="fill-black" />
              </button>
            </TableCell>
          </TableRow>
        ))}
      />
      <div className="mt-6 flex justify-end">
        <Pagination
          dir="ltr"
          nextLabel="بعدی"
          previousLabel="قبلی"
          onPageChange={(p) => setPage?.(p)}
          currentPage={page ?? 1}
          totalPages={pagesCount ?? 1}
        />
      </div>
    </div>
  );
};

export default AdvertisementList;
