import AxiosInstance from "IAxios/AxiosInstance";
import { BackendTemplate } from "api";
import { PagintaionType } from "common/types";
import { SendCustomerNotification, CustomerType, CustomerNotification, CustomerNotificationType } from "common/types/customers";
import { toast } from "react-toastify";

export interface AllCustomersAPIData extends PagintaionType {
    data: CustomerType[];
}

export const getAllCustomers = (params?: { page?: number, mphone?: string, name?: string }) =>
    AxiosInstance.get<AllCustomersAPIData>("customers/", { params: { ...params, limit: 10 } })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))

export interface AllCustomerNotifications extends PagintaionType {
    data?: CustomerNotification[]
}

export interface SendCustoemrNotificationAPI extends BackendTemplate {
    data?: CustomerNotificationType
}

export const getAllCustomerNotifications = (data: { type: "همه", group: "Group" }, page: number) =>
    AxiosInstance.post<AllCustomerNotifications>("customers/notifications", data, { params: { page } })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))

export const sendCustoemrNotification = (data: Partial<SendCustomerNotification>) =>
    AxiosInstance.post<SendCustoemrNotificationAPI>("customers/notification", data)
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))

export interface UpadteCustomerAPIType extends BackendTemplate {
    data?: CustomerType
}

export const updateCustomer = (cid: string, customer: Partial<CustomerType>) =>
    AxiosInstance.put<UpadteCustomerAPIType>(`customers/${cid}`, customer)
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))

export const cancelCustomerNotification = (data: { notificationId: string }) =>
    AxiosInstance.delete("customers/notification", { data })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))
