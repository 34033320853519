import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { Breadcrumbs, Navbar, Sidebar, SidebarDrawer } from "components/common";
import { getLoggedInAdmin } from "api";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices/userSlice";

// Main  pages layout implementation
// outlet will render nested routes in main pathname

function DashboardPagesLayout() {
  const dispatch = useDispatch();

  const [expandSideBar, setExpandSideBar] = useState<boolean>(true);
  const [openSidebar, setOpenSidebar] = useState(false)

  const toggleSidebar = () => {
    if (window.innerWidth < 768) {
      setOpenSidebar((prev) => !prev)
    }
    setExpandSideBar((prev) => !prev)
  };

  useEffect(() => {
    getLoggedInAdmin().then((res) => {
      if (res && res.success && res.data) {
        dispatch(setUser(res.data));
      }
    });
  }, []);

  return (
    <div className="w-screen min-h-screen flex overflow-hidden bg-[#FAFAFA]">
      <div className="h-screen grow overflow-y-scroll w-[80vw]">
        <Navbar toggleSidebar={toggleSidebar} />
        <Breadcrumbs />
        <Outlet />
      </div>
      <Sidebar isExpanded={expandSideBar} />
      <SidebarDrawer isOpen={openSidebar} toggleSidebar={() => setOpenSidebar((prev) => !prev)} />
    </div>
  );
}

export default DashboardPagesLayout;
