import { addRestaurantFeature } from 'api'
import { PenOutline } from 'assets'
import { CusButton, CusInput } from 'common/modules'
import { resFeature } from 'common/types'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

type props = {
  handleAddNewItem?: (type: string, newItem: any) => Promise<void>
}

const RestaurantFacilitiesForm: FC<props> = ({ handleAddNewItem }) => {

  const [loading, setLoading] = useState<boolean>(false)

  const { register, handleSubmit } = useForm<resFeature>()

  const handleAddNewFeature = (data: resFeature) => {
    setLoading(true)
    addRestaurantFeature(data).then((res) => {
      if (res.success) {
        handleAddNewItem?.("feature", data.feature)
      }
    }).finally(() => setLoading(false))
  }

  return (
    <form onSubmit={handleSubmit(handleAddNewFeature)} className='flex flex-col justify-between gap-y-4'>
      <CusInput
        regFunc={() => register("feature")}
        inputStyle={{ border: 0 }}
        leftIcon={<PenOutline />}
        placeHolder='امکانات'
      />
      <CusButton
        type='submit'
        loading={loading}
        className='text-black w-full py-0'>
        ثبت
      </CusButton>
    </form>
  )
}

export default RestaurantFacilitiesForm