import React, { FC, useMemo } from "react";
import { OperationIcon } from "assets";
import CusTable from "components/CusTable/CusTable";
import { Pagination, TableCell, TableRow } from "flowbite-react";
import { persianDateParser } from "common/utils";
import { DiscountType } from "common/types/discounts";
import { PagintaionType } from "common/types";
import { openedModalType } from "pages/dashboard/MarketingPage";

type props = {
  discounts?: DiscountType[]
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  pagesMeta?: PagintaionType
  setOpenedModal: React.Dispatch<React.SetStateAction<openedModalType | undefined>>
}

const DiscountsList: FC<props> = ({ page, setPage, discounts, pagesMeta, setOpenedModal }) => {
  const pagesCount = pagesMeta && Math.ceil(pagesMeta?.totalCount / 10)
  return (
    <div>
      <CusTable
        withHeaderGrid
        withYDivider
        stickyHeader
        noItem={discounts?.length === 0}
        headers={[
          "نام رستوران",
          "نوع تخفیف",
          "درصد",
          "زمان",
          "تاریخ شروع",
          "تاریخ اتمام",
          "وضعیت",
          "جزئیات",
        ]}
        contents={
          discounts?.map((item) => (
            <TableRow>
              <TableCell>{item?.restaurantName}</TableCell>
              <TableCell>{item?.type}</TableCell>
              <TableCell>{item?.discountOccasion?.overallDiscount}</TableCell>
              <TableCell>{item?.discountStartTime?.slice?.(0, 5)} - {item?.discountEndTime?.slice?.(0, 5)}</TableCell>
              <TableCell>{persianDateParser(item?.discountStartDate)}</TableCell>
              <TableCell>{persianDateParser(item?.discountEndDate)}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>
                <button onClick={() => setOpenedModal({ id: 5, title: "نمایش جزئیات", item })}>
                  <OperationIcon className="fill-black" />
                </button>
              </TableCell>
            </TableRow>
          ))
        }
      />
      <div className="mt-6 flex justify-end">
        <Pagination
          dir="ltr"
          nextLabel="بعدی"
          previousLabel="قبلی"
          currentPage={page}
          onPageChange={setPage}
          totalPages={pagesCount ?? 1}
        />
      </div>
    </div>
  );
};

export default DiscountsList;
