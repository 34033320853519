import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CusButton, CusDropdown, CusInput, CusTextArea, CusTimePicker, CusToggle, ImageUploader } from 'common/modules'
import { CategoryType, RestaurantType } from 'common/types'
import { DeleteIcon } from 'assets'
import { AnimatePresence, motion } from "framer-motion"
import { WEEK_DAYS } from 'common/constants'
import { deleteRestaurantImage, deleteRestaurantLogo, updateRestaurant } from 'api'
import { CusMap } from 'components'
import { toast } from 'react-toastify'

type props = {
    item?: RestaurantType
    categories?: CategoryType[]
    features?: string[]
    handleUpdate?: (type: string, value: any, newProperties: any) => Promise<void>
}

const RestaurantInfoForm: FC<props> = ({ item, categories, features, handleUpdate }) => {

    const [selectedCat, setSelectedCat] = useState<any>()
    const [newPhoneNumber, setNewPhoneNumber] = useState("")
    const [loading, setLoading] = useState<boolean>(false)

    const { handleSubmit, register, setValue, watch } = useForm<RestaurantType>({ defaultValues: item })

    useEffect(() => {
        // for -show- default value on dropdown component
        if (item) {
            const defaultCat = categories?.filter((cat) => cat.name === item?.category)[0]
            setSelectedCat({ name: defaultCat?.persianName, value: defaultCat?.name })
        }
    }, [item])

    const handleAddNewPhoneNumber = () => {
        if (Boolean(newPhoneNumber)) {
            setValue("phone", watch("phone").concat(newPhoneNumber))
            setNewPhoneNumber("")
        }
    }

    const handleDeletePhoneNumber = (phone: string) => {
        const newPhoneArr = watch("phone").filter((ph) => ph !== phone)
        setValue("phone", newPhoneArr)
    }

    const onDeleteLogo = (url?: string | File, newArr?: (string | File | undefined)[]) => {

        if (item?.id && url && typeof url === "string") {
            deleteRestaurantLogo(item?.id, url).then((res) => {

                if (res?.success && newArr) {
                    setValue("logo", undefined)
                }
            })
        }
    }

    const onDeleteImage = (url?: string | File, newArr?: (string | File | undefined)[]) => {
        if (item?.id && url && typeof url === "string") {
            deleteRestaurantImage(item?.id, url).then((res) => {
                if (res?.success && newArr) {
                    setValue("images", newArr)
                }
            })
        }
    }

    const onSubmit = (data: RestaurantType) => {

        const { images, ...rest } = data
        let dataTosend: any
        if (item && item?.images.length < data.images.length) {
            dataTosend = { ...rest, images: images.at(-1) }
        } else {
            dataTosend = rest
        }

        if (dataTosend) {
            if (item?.id) {
                setLoading(true)
                updateRestaurant(item.id, dataTosend).then((res) => {
                    if (res?.success) {
                        handleUpdate?.("restaurants", data.id, { ...watch(), logo: res?.data?.logo })
                        toast.success("رستوران با موفقیت ویرایش شد.")
                    }
                }).finally(() => setLoading(false))
            }
        }
    }

    const logoGen = () => {
        const imageUrl = watch("logo")
        if (typeof imageUrl === "string") {
            return [imageUrl]
        }
    }

    const images = watch("images")

    const unUploadmage = (file: File) => {
        if (images[0]?.toString().endsWith("no-restaurant-image.png")) {
            setValue("images", [file])
        } else {
            setValue("images", [...images, file])
        }
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className='grid md:grid-cols-2 gap-4'>
                <div id='res-type-form-right' className='col-span-1'>
                    <div className='flex gap-x-2'>
                        <CusInput
                            fullWidth
                            placeHolder='نام رستوران'
                            regFunc={() => register("name")}
                            inputStyle={{ border: 0 }}
                        />
                        <div className='w-full'>
                            <CusDropdown
                                label='نوع رستوران'
                                selected={selectedCat}
                                onChange={(v) => { setSelectedCat(v); setValue("persianCatName", v.name.toString()); setValue("category", v.value.toString()) }}
                                items={categories?.map((item) => (
                                    { name: item.persianName, value: item.name }
                                ))} />
                        </div>
                    </div>

                    <p className='mb-2 mt-3'>زمان فعالیت</p>
                    <div className='flex gap-x-2'>
                        <div className='w-full'>
                            <CusDropdown
                                label={watch("activeHours.days.start")}
                                onChange={(e) => setValue("activeHours.days.start", e.name)}
                                items={WEEK_DAYS.map((d) => ({ name: d.name, value: d.id }))}
                            />
                        </div>
                        <div className='w-full'>
                            <CusDropdown
                                label={watch("activeHours.days.end")}
                                onChange={(e) => setValue("activeHours.days.end", e.name)}
                                items={WEEK_DAYS.map((d) => ({ name: d.name, value: d.id }))}
                            />
                        </div>
                    </div>

                    <p className='mb-2 mt-3'>لوگو</p>
                    <ImageUploader
                        isMultiple={false}
                        defaultImageUrls={logoGen()}
                        onUpload={(file) => setValue("logo", file)}
                        onDelete={(newArr) => onDeleteLogo(watch("logo"), newArr)}
                    />

                    <p className='mb-2 mt-6'>شماره تماس</p>
                    <div className='bg-appGray/10 rounded-lg flex items-center'>
                        <CusButton
                            className='py-0 text-black w-36'
                            onClick={handleAddNewPhoneNumber}
                        >
                            + اضافه
                        </CusButton>
                        <CusInput
                            dir='ltr'
                            fullWidth
                            className='py-0'
                            placeHolder='+98'
                            value={newPhoneNumber}
                            setter={setNewPhoneNumber}
                            inputStyle={{ border: 0, textAlign: "left" }}
                        />
                    </div>
                    <div className='mt-4 flex flex-wrap gap-4 overflow-y-scroll max-h-[130px] -mx-4 px-4 pb-6'>
                        <AnimatePresence>
                            {watch("phone").map((ph, i) => (
                                <motion.div
                                    key={ph + i}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    className='flex items-center justify-center gap-x-2 bg-white drop-shadow-lg rounded-lg w-[calc(50%-8px)] px-4 py-2 max-h-[50px]'
                                >
                                    <p className='text-sm'>{ph}</p>
                                    <CusButton
                                        onClick={() => handleDeletePhoneNumber(ph)}
                                        loadingWidth={10}
                                        loadingColor="black"
                                        className="p-2 bg-rose-300/50 justify-center items-center w-8 h-8 rounded-full bg-opacity-70 drop-shadow-md">
                                        <DeleteIcon className="scale-150" />
                                    </CusButton>
                                </motion.div>
                            ))}
                        </AnimatePresence>
                    </div>
                </div>
                <div id='res-type-form-left' className='col-span-1'>

                    <CusInput
                        fullWidth
                        regFunc={() => register("beaconUUID")}
                        placeHolder='uuid'
                        inputStyle={{ border: 0 }}
                    />

                    <div className='flex gap-x-2 mt-11'>
                        <div className='w-full'>
                            <CusTimePicker
                                value={watch("activeHours.hours.open")}
                                onChange={({ formatted, meridiem }: { formatted: string, meridiem: string }) => {
                                    const hh = formatted.split(":")[0]
                                    const mm = formatted.split(":")[1].split(" ")[0]
                                    const formattedTime = `${hh.length < 2 ? 0 + hh : hh}:${mm.length < 2 ? 0 + mm : mm} ${meridiem.toUpperCase()}`
                                    setValue("activeHours.hours.open", formattedTime)
                                }}
                            />
                        </div>
                        <div className='w-full'>
                            <CusTimePicker
                                value={watch("activeHours.hours.close")}
                                onChange={({ formatted, meridiem }: { formatted: string, meridiem: string }) => {
                                    const hh = formatted.split(":")[0]
                                    const mm = formatted.split(":")[1].split(" ")[0]
                                    const formattedTime = `${hh.length < 2 ? 0 + hh : hh}:${mm.length < 2 ? 0 + mm : mm} ${meridiem.toUpperCase()}`
                                    setValue("activeHours.hours.close", formattedTime)
                                }}
                            />
                        </div>
                    </div>

                    <p className='mb-2 mt-3'>تصاویر</p>
                    <ImageUploader
                        isMultiple
                        defaultImageUrls={images}
                        onDelete={(newArr, i) => onDeleteImage(watch("images")[i], newArr)}
                        onUpload={unUploadmage}
                    />
                    <div>
                        <p className='mb-2 mt-6'>آدرس</p>
                        <CusTextArea
                            regFunc={() => register("address")}
                            inputStyle={{ border: 0 }}
                            placeHolder='شهر - خیابان اصلی - خیابان فرعی - کوچه - پلاک'
                            className='h-[40px] mb-3 overflow-hidden'
                        />
                    </div>
                    <div className='h-[15vh] rounded-lg overflow-hidden'>
                        <CusMap
                            onChange={(pos) => {
                                if (pos) {
                                    const posStr = pos.toString()
                                    const posArr = posStr.split(',')
                                    const modifiedPosArr = posArr.reverse()
                                    setValue("location.coordinates", modifiedPosArr as any)
                                }
                            }}
                            latLng={
                                {
                                    lat: Number(watch("location.coordinates.1")),
                                    lng: Number(watch("location.coordinates.0"))
                                }
                            }
                        />
                    </div>
                </div>

                <div>
                    <p className='mb-2'>شماره شبا</p>
                    <CusInput
                        placeHolder='شماره شبا'
                        inputStyle={{ border: 0 }}
                        regFunc={() => register("sheba")}
                        leftIcon={<p className='text-palette-text-inputsIcon/30'>IR</p>}
                    />
                </div>
                <div>
                    <CusToggle
                        className='w-1/2 mt-10'
                        label='ارزش افزوده'
                        defaultValue={watch("valueAdded")}
                        onChange={(v) => setValue("valueAdded", v)}
                    />
                </div>

                <div className='col-span-full'>
                    <p className='mb-2 mt-3'>دسترسی</p>
                    <div className='flex gap-x-4'>
                        <CusToggle
                            label='ممنوعیت رستوران'
                            defaultValue={!watch("isActive")}
                            onChange={(v) => setValue("isActive", v ? false : true)}
                        />
                        <CusToggle
                            label='تعطیلی رستوران'
                            defaultValue={!watch("isOpen")}
                            onChange={(v) => setValue("isOpen", v ? false : true)}
                        />
                    </div>
                </div>

                <div className='col-span-full'>
                    <p className='mb-2 mt-3'>امکانات</p>
                    <div className='grid grid-cols-4 gap-4'>
                        {features?.map((f) => (
                            <CusToggle
                                key={f}
                                label={f}
                                defaultValue={watch("facilities").includes(f)}
                                onChange={(v) => {
                                    if (v) {
                                        setValue("facilities", [...watch("facilities"), f])
                                    } else {
                                        const newFacilities = watch("facilities").filter((item) => item !== f)
                                        setValue("facilities", newFacilities)
                                    }
                                }}
                            />
                        ))}
                    </div>
                </div>

                <div className='col-span-full'>
                    <p className='mb-2 mt-3'>ویژگی ها</p>
                    <div className='flex flex-wrap gap-x-4'>
                        <CusToggle
                            label='تحویل گارسون'
                            defaultValue={watch("innerDelivery")}
                            onChange={(v) => {
                                setValue("totalTables", undefined)
                                setValue("innerDelivery", v)
                            }}
                        />

                        <CusInput
                            min={0}
                            className='w-24'
                            inputType='number'
                            placeHolder='تعداد میز'
                            value={watch("totalTables")}
                            disabled={!watch("innerDelivery")}
                            setter={(v: any) => setValue("totalTables", v)}
                        />

                        <CusToggle
                            label='کافه'
                            defaultValue={watch("hasCafe")}
                            onChange={(v) => setValue("hasCafe", v)}
                        />
                    </div>
                </div>
            </div>
            <div className='flex gap-x-2 items-end mt-8'>
                <CusButton className='bg-appGray/50 enabled:hover:bg-appGray/80 w-28 text-black'>انصراف</CusButton>
                <CusButton
                    loading={loading}
                    className='w-56 text-black'
                    onClick={handleSubmit(onSubmit)}
                >
                    ثبت
                </CusButton>
            </div>
        </form>
    )
}

export default RestaurantInfoForm