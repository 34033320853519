import React, { FC } from "react";
import { CardType } from "./CardsContainer";
import { iconBgColorGenerator } from "common/utils";

type props = {
  card?: CardType;
  index: number;
  isVertical?: boolean;
  className?: string
};

const InfoCard: FC<props> = ({ card, index, isVertical, className }) => {
  if (card) {
    const { title, headline } = card;
    const iconBg = iconBgColorGenerator(index + 1, 15);
    return (
      <div
        className={`bg-white p-4 rounded-lg shadow-xl ${isVertical ? "col-span-1" : "w-64"} ${className}`}
      >
        <div
          className={`flex ${isVertical ? "flex-col gap-y-2" : "flex-row"
            } items-center gap-x-4`}
        >
          <div className="p-3 rounded-lg" style={{ backgroundColor: iconBg }}>
            {card.icon && <card.icon />}
          </div>
          <div>
            <p
              className={`text-base font-medium ${isVertical ? "text-center text-sm" : "text-base"
                }`}
            >
              {title}
            </p>
            {headline && (
              <p
                className={`text-xs text-palette-text-headlines ${isVertical ? "text-center" : ""
                  }`}
              >
                {headline}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default InfoCard;
